export async function ElementOverContainer(stageRef, elements, item){
    // get containers
    
    const containers = elements.filter(i => i.type == 'container');

    // all layers at position
    const inter = await stageRef.getAllIntersections(stageRef.getPointerPosition());

    // is one of the layers a container at position
    const findContainerAtPos = containers.filter(o => inter.some((j) => (o.id === j.attrs.id && j.attrs.id !== item.id)));

    if(findContainerAtPos.length === 0) return false;

    // Get current element's index
    const elementIndex = elements.findIndex(x => x.id === item.id);
    
    // Get container's index
    const containerIndex = elements.findIndex(x => x.id === findContainerAtPos[0].id);

    // Only allow adding to container if element's index is less than container's index
    // (element is on a higher layer than container)
    if(elementIndex > containerIndex) {
        return false;
    }

    return findContainerAtPos[0];
}    

// export async function ElementOverContainer(stageRef, elements, item) {
//     // Get all containers
//     const containers = elements.filter(i => i.type === 'container');
    
//     // Get current element's index in the elements array
//     // Higher index = higher in visual stack (closer to top) due to toReversed() render
//     const currentElementIndex = elements.findIndex(el => el.id === item.id);
    
//     // Get all intersecting elements at current pointer position
//     const intersections = await stageRef.getAllIntersections(stageRef.getPointerPosition());
    
//     // Filter containers that:
//     // 1. Are at the current position (using Konva intersection)
//     // 2. Are not the current item itself
//     // 3. Are above the current element in visual stack (higher index due to reverse)
//     const findContainerAtPos = containers.filter(container => {
//         // Check if container intersects with pointer position
//         const isIntersecting = intersections.some(
//             intersection => container.id === intersection.attrs.id && intersection.attrs.id !== item.id
//         );
        
//         if (!isIntersecting) return false;
        
//         // Get container's position in elements array
//         const containerIndex = elements.findIndex(el => el.id === container.id);
        
//         // Container must have higher index than current element to be above it
//         // due to toReversed() rendering
//         return containerIndex > currentElementIndex;
//     });

//     // Return the first valid container or false if none found
//     if (findContainerAtPos.length !== 0) {
//         // Get the topmost container (highest index in elements array due to reverse)
//         const topmostContainer = findContainerAtPos.reduce((topmost, current) => {
//             const topmostIndex = elements.findIndex(el => el.id === topmost.id);
//             const currentIndex = elements.findIndex(el => el.id === current.id);
//             return currentIndex > topmostIndex ? current : topmost;
//         });
//         return topmostContainer;
//     }
    
//     return false;
// }