import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Text as KonvaText, Circle } from 'react-konva';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import { usePrototypeContext } from '../../../context/prototype/prototypecontext';
import PlayerHeader from '../../../components/player/header/playerheader';
import { fontSelection } from '../../../fonts/fonts';

import PlayerCanvas from '../../../components/player/playercanvas';

// How is the player going to work
// get screens with elements from canvas
// deploy to subdomain
// load design objects

// screen 1
//// text field
////// prototype
//////// enabled: true
////////// proto
//////////// trigger : OnClick
//////////// interaction : Navigate
////////////// Screen : Screen 2
//////////// passdata : true
////////////// value : Input
////////// proto
//////////// trigger : OnMouseHover
//////////// interaction : ChangeValue
////////////// Item : Colour
//////////////// Value : '#000'
// screen 2
// ...

// need functions to take each proto object
// break down request
// first function sorts by trigger

function Player() {

    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;

    const [loaded, setLoaded] = useState(false);
    const {id} = useParams();

    const [top, setTop] = useState(0);
    const [bottom, setBottom] = useState(0);
    const [left, setLeft] = useState(0);
    const [right, setRight] = useState(0);

    const [screenX, setScreenX] = useState(0);
    const [screenY, setScreenY] = useState(0);
    const [screenScale, setScreenScale] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);
    const [screenHeight, setScreenHeight] = useState(0);

    const [fontArr, setFontArr] = useState(fontSelection);

    const prototypeContext = usePrototypeContext();

    useEffect(()=>{
        prototypeContext.getFile(id);
        prototypeContext.getScreens(id);
        
    },[]);

    useEffect(()=>{
        if(loaded == false){
            prototypeContext.loadElements(id); 
            //all elements have been loaded
            //prototypeContext.setAllElementsLoaded(true);
            setLoaded(true);
            //console.log('loaded all elements')
        }
    },[prototypeContext.currentRenderedScreen]);

    useEffect(()=>{

        if(prototypeContext.loaded){
            prototypeContext.sortElements();

            const y = screenY;
            const x = screenX;
            const top = y - ((screenHeight/2) * screenScale) + 2
            const left = x - ((screenWidth/2) * screenScale) + 2
            setTop(top)
            setBottom(top + (screenHeight * screenScale));
            setLeft(left);
            setRight(left + (screenWidth * screenScale));
        }

    },[prototypeContext.loaded])

    useEffect(()=>{

        const loadFileFonts = async() => {
            let fileFonts = [];
            if(prototypeContext.element.length == 0) return;
            // Wait for all elements to be loaded
            // get fonts in file and load them
            prototypeContext.element.map(async(item)=>{
                const font = item.font;
                const fontExists = fileFonts.some(i => i === font)
                if(!font || fontExists) return;
                fileFonts.push(font);
                const fontFilter = fontArr.filter((i) => i.family == font);
                const loaded = await fontFilter[0].load();
                // Load the font itself
                const { fontFamily, ...otherInfo } = loaded.loadFont();
            });
        }
        loadFileFonts();
    },[prototypeContext.element])
    
 

    return(
        <>
            <div 
            style={{
                position: 'fixed', 
                top: 0,
                left: 0,
                zIndex: 1000, 
                width: '100vw',  // Change from canvasWidth
                height: '100vh', // Change from canvasWidth
                backgroundColor: '#22232E',
                clipPath: `polygon(0% 0%, 0% 100%, ${left}px 100%, ${left}px ${top}px, ${right}px ${top}px, ${right}px ${bottom}px, ${left}px ${bottom}px, ${left}px 100%, 100% 100%, 100% 0%)`,
            }}/>
            <div style={{background: '#22232E'}}> 
                <PlayerHeader/>     
                <PlayerCanvas setScreenX={setScreenX} setScreenY={setScreenY} setScreenScale={setScreenScale} setClipScreenWidth={setScreenWidth} setClipScreenHeight={setScreenHeight}/>   
            </div>
        </>
    )
}

export default Player;