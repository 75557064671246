import React, {useEffect, useState} from 'react';
import { Theme, ThemePanel } from '@radix-ui/themes';
import { getAvailableFonts } from "@remotion/google-fonts";
import '@radix-ui/themes/styles.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Editor from './pages/editor/editor';
import Player from './pages/player/player/player';
import Dashboard from './pages/dashboard/dashboard'; 
import Login from './pages/auth/login/login';
import Signup from './pages/auth/signup/signup';
import SelectPlan from './pages/auth/selectPlan/selectplan';
import YourTeam from './pages/auth/yourTeam/yourteam';
import Checkout from './pages/auth/checkout/checkout';
import InviteMemberPassword from './pages/auth/inviteMemberPassword/inviteMemberPassword';
import CheckoutFinished from './pages/auth/checkoutFinished/checkoutFinished';
import RequireAuth from './components/auth/requireauth';
import RequireInvite from './components/auth/requireinvite';

import { supabase } from './supabase/supabaseclient';

//contexts
import { EditorContextProvider } from './context/editor/editorcontext';
import { AuthContextProvider, useAuthContext } from './context/auth/authcontext';
import { PrototypeContextProvider } from './context/prototype/prototypecontext';

function App() {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const authContext = useAuthContext();

    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        //console.log('getting session', session)
        setSessionData(session);
        authContext.setUserSession(session);
        setLoading(true);
      })

      const {data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
        setSessionData(session);
        authContext.setUserSession(session);
      })

      return () => subscription.unsubscribe()
    }, []);

    if (process.env.NODE_ENV === 'production') {
      console.log = () => {}
      console.error = () => {}
      console.debug = () => {}
    }
    


  return (
    <AuthContextProvider sessionData={sessionData}>
      <EditorContextProvider>
        <PrototypeContextProvider>
          <Theme>
            { loading &&
              <Routes>
                <Route path='/' element={<Navigate to={'/dashboard'} replace/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/signup' element={<Signup/>}/>
                <Route path='/join-team/password/:email/:id' element={
                  <RequireInvite>
                    <InviteMemberPassword/>
                  </RequireInvite>
                }/>
                <Route path='/checkout/return' element={
                  <RequireAuth session={sessionData}>
                    <CheckoutFinished/>
                  </RequireAuth>
                }/>
                <Route path='/signup/select-plan' element={
                  <RequireAuth session={sessionData}>
                    <SelectPlan/>
                  </RequireAuth>
                }/>
                <Route path='/signup/your-team/:in_signup/:company?/:team?/:team_id?' element={
                  <RequireAuth session={sessionData}>
                    <YourTeam/>
                  </RequireAuth>
                }/>
                <Route path='/signup/checkout/:num_users/:teamId' element={
                  <RequireAuth session={sessionData}>
                    <Checkout/>
                  </RequireAuth>
                }/>
                <Route path='/dashboard' element={
                  <RequireAuth session={sessionData}>
                    <Dashboard/>
                  </RequireAuth>
                }/>
                <Route path='/design/:id' element={
                  <RequireAuth session={sessionData}>
                    <Editor/>
                  </RequireAuth>
                }/>
                <Route path='/proto/:id' element={
                  <RequireAuth session={sessionData}>
                    <Player/>
                  </RequireAuth>
                  }/>
              </Routes>
            }
          </Theme>
        </PrototypeContextProvider>
      </EditorContextProvider>  
    </AuthContextProvider>   
  );
}
//stop scrolling
document.body.style.overflow = "hidden"
export default App;
