// import React from 'react';

// const baseStyles = (element, isChild = false) => ({
//   position: isChild ? 'absolute' : 'relative',
//   width: element.width ? `${element.width}px` : null,
//   height: element.height ? `${element.height}px` : null,
//   left: isChild && element.xpos ? `${element.xpos}px` : null,
//   top: isChild && element.ypos ? `${element.ypos}px` : null,
//   boxSizing: 'border-box'
// });

// const borderStyles = (element) => {
//   if (!element.border_width) return {};
  
//   return {
//     borderWidth: `${element.border_width}px`,
//     borderStyle: 'solid',
//     borderColor: element.border_colour || 'transparent',
//     borderTopLeftRadius: element.border_radius_tl ? `${element.border_radius_tl}px` : '0px',
//     borderTopRightRadius: element.border_radius_tr ? `${element.border_radius_tr}px` : '0px',
//     borderBottomLeftRadius: element.border_radius_bl ? `${element.border_radius_bl}px` : '0px',
//     borderBottomRightRadius: element.border_radius_br ? `${element.border_radius_br}px` : '0px'
//   };
// };

// const typographyStyles = (element) => ({
//   fontFamily: element.font || 'inherit',
//   fontSize: element.font_size ? `${element.font_size}px` : 'inherit',
//   fontWeight: element.font_weight || 'normal',
//   color: element.font_colour || 'inherit',
//   lineHeight: element.line_height ? `${element.line_height}px` : 'normal',
//   letterSpacing: element.letter_spacing ? `${element.letter_spacing}px` : 'normal',
//   textAlign: element.text_alignment || 'left'
// });

// const elementConfig = {
//     container: {
//         tag: 'div',
//         hasChildren: true,
//         getStyles: (element, isChild) => ({
//           width: element.width ? `${element.width}px` : null,
//           height: element.height ? `${element.height}px` : null,
//           position: isChild ? 'absolute' : 'relative',
//           left: isChild && element.xpos ? `${element.xpos}px` : null,
//           top: isChild && element.ypos ? `${element.ypos}px` : null,
//           backgroundColor: element.fill || 'transparent',
//           borderWidth: element.border_width ? `${element.border_width}px` : null,
//           borderStyle: element.border_width ? 'solid' : null,
//           borderColor: element.border_colour || null,
//           borderTopLeftRadius: element.border_radius_tl ? `${element.border_radius_tl}px` : null,
//           borderTopRightRadius: element.border_radius_tr ? `${element.border_radius_tr}px` : null,
//           borderBottomLeftRadius: element.border_radius_bl ? `${element.border_radius_bl}px` : null,
//           borderBottomRightRadius: element.border_radius_br ? `${element.border_radius_br}px` : null
//         }),
//         renderContent: (element) => {
//           if (!element.children?.length) return '';
          
//           return element.children.map(child => {
//             const childConfig = elementConfig[child.type];
//             if (!childConfig) return '';
      
//             if (childConfig.renderContent) {
//               return childConfig.renderContent(child);
//             }
            
//             const childStyles = generateStyles(child, true);
//             const childProps = childConfig.getProps ? generateProps(childConfig, child) : '';
//             const childContent = child[childConfig.content] || '';
            
//             return `<${childConfig.tag}${childProps} style={{
//       ${childStyles}
//             }}>
//               ${childContent}
//             </${childConfig.tag}>`;
//           }).join('\n    ');
//         }
//       },
//   textfield: {
//     tag: 'input',
//     needsState: true,
//     getProps: (element) => ({
//       type: 'text',
//       value: '{inputValue}',
//       onChange: '{handleChange}',
//       placeholder: element.placeholder || ''
//     }),
//     getStyles: (element, isChild) => ({
//       ...baseStyles(element, isChild),
//       ...borderStyles(element),
//       ...typographyStyles(element),
//       backgroundColor: element.fill || 'transparent',
//       padding: '0 8px',
//       outline: 'none',
//       '&::placeholder': {
//         color: element.placeholder_color || '#757575'
//       }
//     })
//   },
//   button: {
//     tag: 'button',
//     needsState: true,
//     content: 'value',
//     getProps: () => ({
//       onClick: '{handleClick}'
//     }),
//     getStyles: (element, isChild) => ({
//       ...baseStyles(element, isChild),
//       ...borderStyles(element),
//       ...typographyStyles(element),
//       backgroundColor: element.fill || 'transparent',
//       cursor: 'pointer',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       transition: 'all 0.2s ease-in-out',
//       '&:hover': {
//         backgroundColor: element.hover_fill || element.fill,
//         borderColor: element.hover_border_colour || element.border_colour,
//         borderWidth: element.hover_border_width ? `${element.hover_border_width}px` : element.border_width
//       }
//     })
//   },
//   text: {
//     tag: 'p',
//     content: 'value',
//     getStyles: (element, isChild) => ({
//       ...baseStyles(element, isChild),
//       ...typographyStyles(element),
//       margin: 0,
//       padding: 0
//     })
//   },
//   image: {
//     tag: 'img',
//     getProps: (element) => ({
//       src: 'https://images.unsplash.com/photo-1515266591878-f93e32bc5937?q=80&w=2592&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//       alt: 'image'
//     }),
//     getStyles: (element, isChild) => ({
//       ...baseStyles(element, isChild),
//       ...borderStyles(element),
//       objectFit: element.aspect_ratio || 'cover',
//       padding: 0
//     })
//   },
//   switch: {
//     tag: 'div',
//     needsState: true,
//     renderContent: (element) => {
//       return `<div 
//         style={{
//           width: ${element.width},
//           height: ${element.height},
//           transition: 'ease-in-out',
//           transitionDuration: '.3s',
//           backgroundColor: switcher ? '${element.active_colour}' : '${element.disabled_colour}',
//           padding: 10,
//           borderTopLeftRadius: ${element.border_radius_tl},
//           borderTopRightRadius: ${element.border_radius_tr},
//           borderBottomLeftRadius: ${element.border_radius_bl},
//           borderBottomRightRadius: ${element.border_radius_br},
//           boxSizing: 'border-box'
//         }} 
//         onClick={() => setSwitcher(!switcher)}
//       > 
//         <div style={{
//           width: ${element.height}, 
//           height: '100%', 
//           backgroundColor: '${element.notch_colour}',
//           transition: 'ease-in-out',
//           transitionDuration: '.3s',
//           borderRadius: 100,
//           transform: switcher ? \`translateX(\${${element.width} - ${element.height} - 20}px)\` : 'translateX(0px)',
//         }}/>
//       </div>`;
//     }
//   }
// };

// const generateStateAndHandlers = (element) => {
//   let state = [];
//   let handlers = [];
//   let imports = ['useState'];

//   const needsTextField = element.type === 'textfield' || 
//     element.children?.some(child => child.type === 'textfield');
//   const needsSwitch = element.type === 'switch' || 
//     element.children?.some(child => child.type === 'switch');
//   const needsButton = element.type === 'button' || 
//     element.children?.some(child => child.type === 'button');

//   if (needsTextField) {
//     state.push(`const [inputValue, setInputValue] = useState("");
//   const handleChange = (e) => setInputValue(e.target.value);`);
//   }

//   if (needsSwitch) {
//     state.push(`const [switcher, setSwitcher] = useState(false);`);
//   }

//   if (needsButton) {
//     handlers.push(`const handleClick = () => {
//     console.log("Button clicked");
//   };`);
//   }

//   return {
//     imports: imports.join(', '),
//     stateCode: state.join('\n  '),
//     handlersCode: handlers.join('\n  ')
//   };
// };

// const generateStyles = (element, isChild = false) => {
//   const config = elementConfig[element.type];
//   if (!config || !config.getStyles) return '';

//   const styles = config.getStyles(element, isChild);
  
//   return Object.entries(styles)
//     .filter(([_, value]) => value != null)
//     .map(([key, value]) => {
//       if (typeof value === 'object') {
//         const nestedStyles = Object.entries(value)
//           .map(([k, v]) => `      ${k}: "${v}"`)
//           .join(',\n');
//         return `    "${key}": {\n${nestedStyles}\n    }`;
//       }
//       return `    ${key}: "${value}"`;
//     })
//     .join(',\n');
// };

// const generateProps = (config, element) => {
//   if (!config.getProps) return '';
  
//   const props = config.getProps(element);
//   return ' ' + Object.entries(props)
//     .map(([key, value]) => {
//       if (value.startsWith('{')) {
//         return `${key}=${value}`;
//       }
//       return `${key}="${value}"`;
//     })
//     .join(' ');
// };

// const extractCoreComponent = (element) => {
//   const config = elementConfig[element.type];
//   if (!config) return null;

//   if (config.renderContent) {
//     return config.renderContent(element);
//   }

//   const styles = generateStyles(element);
//   const props = generateProps(config, element);
  
//   let content = '';
//   if (config.hasChildren && element.children?.length) {
//     content = element.children
//       .map(child => {
//         const childConfig = elementConfig[child.type];
//         if (!childConfig) return '';
        
//         if (childConfig.renderContent) {
//           return childConfig.renderContent(child);
//         }
        
//         const childStyles = generateStyles(child, true);
//         const childProps = generateProps(childConfig, child);
//         const childContent = child[childConfig.content] || '';
        
//         return `  <${childConfig.tag}${childProps} style={{
// ${childStyles}
//     }}>
//       ${childContent}
//     </${childConfig.tag}>`;
//       })
//       .filter(Boolean)
//       .join('\n');
//   } else if (config.content) {
//     content = element[config.content] || '';
//   }

//   return `<${config.tag}${props} style={{
// ${styles}
//   }}>
//   ${content}
// </${config.tag}>`;
// };

// export const generateComponentCode = (element) => {
//   const componentName = element.type.charAt(0).toUpperCase() + element.type.slice(1) + 'Component';
//   const { imports, stateCode, handlersCode } = generateStateAndHandlers(element);
//   const jsxContent = extractCoreComponent(element);
  
//   if (!jsxContent) {
//     throw new Error('Failed to generate component code');
//   }

//   return `import React, { ${imports} } from 'react';

// const ${componentName} = () => {
//   ${stateCode}
//   ${handlersCode}
//   return (
// ${jsxContent}
//   );
// };

// export default ${componentName};`;
// };
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx';

const elementConfig = {
  textfield: {
    tag: 'input',
    props: {
      value: '{inputValue}',
      onChange: '{handleInputChange}',
      placeholder: 'placeholder'
    },
    needsState: true
  },
  button: {
    tag: 'button',
    props: {
      onClick: '{handleClick}'
    },
    content: 'value',
    needsClick: true
  },
  text: {
    tag: 'p',
    content: 'value'
  },
  image: {
    tag: 'img',
    props: {
      src: 'https://images.unsplash.com/photo-1515266591878-f93e32bc5937?q=80&w=2592&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'image_name'
    }
  },
  switch: {
    tag: 'div',
    props: {
      onClick: '{() => setIsChecked(!isChecked)}',
    },
    needsState: true,
    renderContent : (element) => {
        return `
        <div style={{
            width: ${element.height}, 
            height: '100%', 
            backgroundColor: '${element.notch_colour}',
            transition: 'ease-in-out',
            transitionDuration: '.3s',
            borderRadius: 100,
            transform: isChecked ? \`translateX(\${${element.width} - ${element.height} - 20}px)\` : 'translateX(0px)',
        }}/>`    
    }
  },
  container: {
    tag: 'div',
    hasChildren: true
  }
};

const extractStyles = (element, isChild = false) => {
  const styleMap = {
    width: element.width ? `${element.width}` : null,
    height: element.height ? `${element.height}` : null,
    fontFamily: element.font ? `"${element.font}"` : null,
    fontSize: element.font_size ? `${element.font_size}` : null,
    color: element.font_colour ? `"${element.font_colour}"` : null,
    backgroundColor: element.fill ? `"${element.fill}"` : null,
    borderWidth: element.border_width ? `${element.border_width}` : 0,
    borderColor: element.border_colour ? `"${element.border_colour}"` : null,
    borderStyle: element.border_width ? `"solid"` : null,
    borderTopLeftRadius: element.border_radius_tl ? `${element.border_radius_tl}` : null,
    borderTopRightRadius: element.border_radius_tr ? `${element.border_radius_tr}` : null,
    borderBottomLeftRadius: element.border_radius_bl ? `${element.border_radius_bl}` : null,
    borderBottomRightRadius: element.border_radius_br ? `${element.border_radius_br}` : null,
    position: isChild ? `"absolute"` : `"relative"`,
    left: isChild && element.xpos ? `${element.xpos}` : null,
    top: isChild && element.ypos ? `${element.ypos}` : null,
    objectFit: element.aspect_ratio ? `"${element.aspect_ratio}"` : null,
    lineHeight: element.line_height ? `${element.line_height}` : null,
    letterSpacing: element.letter_spacing ? `${element.letter_spacing}` : null,
    textAlign: element.text_alignment ? `"${element.text_alignment}"` : null,
  };

  // Switch-specific styles
  if (element.type === 'switch') {
    styleMap.boxSizing = `"border-box"`;
    styleMap.borderWidth = null;
    styleMap.borderColor = null;
    styleMap.borderStyle = null;
    styleMap.padding = 10;
    styleMap.backgroundColor = `isChecked ? "${element.active_colour}" : "${element.disabled_colour}"`;
    styleMap.transition = `"ease-in-out"`;
    styleMap.transitionDuration = `".3s"`;
  }

  if(element.type === 'text'){
    styleMap.width = element.width ? `${element.width+1}` : null;
  }

  const styles = Object.entries(styleMap)
    .filter(([_, value]) => value != null)
    .map(([key, value]) => `     ${key}: ${value}`)
    .join(',\n');

  return styles || '// Add custom styles here';
};

const generateProps = (config, element) => {
    if (!config.props) return '';
    
    const props = Object.entries(config.props)
      .map(([key, value]) => {
        if (value.startsWith('{')) {
          return `${key}=${value}`;
        }
        // If value starts with http, treat it as a literal URL
        if (value.startsWith('http')) {
          return `${key}="${value}"`;
        }
        const propValue = element[value];
        return propValue ? `${key}="${propValue}"` : null;
      })
      .filter(Boolean)
      .join(' ');
  
    return props ? ` ${props}` : '';
  };

export const extractCoreComponent = (element) => {
  const config = elementConfig[element.type];
  if (!config) return null;

  const styles = extractStyles(element);
  const props = generateProps(config, element);
  
  let content = '';
  if (config.hasChildren && element.children?.length) {
    content = element.children
      .map(child => {
        const childConfig = elementConfig[child.type];
        if (!childConfig) return '';
        
        const childStyles = extractStyles(child, true);
        const childProps = generateProps(childConfig, child);
        const childContent = child[childConfig.content] || '';
        
    return `<${childConfig.tag}${childProps} style={{
${childStyles}
    }}>
      ${childContent}
    </${childConfig.tag}>`;
      })
      .filter(Boolean)
      .join('\n');
  } else if (config.renderContent) {
    content = config.renderContent(element);
  }else if (config.content && element[config.content]) {
    content = element[config.content];
  }

  return `<${config.tag}${props} style={{
${styles}
  }}>
  ${content}
</${config.tag}>`;
};

export const generateComponentCode = (element) => {
  const componentName = element.type.charAt(0).toUpperCase() + element.type.slice(1) + 'Component';
  const jsxContent = extractCoreComponent(element);
  
  if (!jsxContent) {
    throw new Error('Failed to generate component code');
  }

  let stateCode = '';
  let importsCode = `import React, { useState } from \'react\';\n`;
  
  // Generate state and handlers based on element type and children
  const needsTextField = element.type === 'textfield' || 
    element.children?.some(child => child.type === 'textfield');
  const needsSwitch = element.type === 'switch' || 
    element.children?.some(child => child.type === 'switch');
  const needsButton = element.type === 'button' || 
    element.children?.some(child => child.type === 'button');

  if (needsTextField) {
    stateCode += 'const [inputValue, setInputValue] = useState("");\n  ';
    stateCode += 'const handleInputChange = (e) => setInputValue(e.target.value);\n  ';
  }

  if (needsSwitch) {
    stateCode += 'const [isChecked, setIsChecked] = useState(false);\n  ';
  }

  if (needsButton) {
    stateCode += 'const handleClick = () => console.log("Button clicked");\n  ';
  }

  // Add hover effects for buttons
  let useEffectCode = '';
  if (element.type === 'button' || element.children?.some(child => child.type === 'button')) {
    useEffectCode = `
  useEffect(() => {
    const button = document.querySelector('button');
    if (button) {
      button.addEventListener('mouseenter', () => {
        button.style.backgroundColor = "${element.hover_fill}";
        button.style.borderColor = "${element.hover_border_colour}";
        button.style.borderWidth = "${element.hover_border_width}px";
      });
      button.addEventListener('mouseleave', () => {
        button.style.backgroundColor = "${element.fill}";
        button.style.borderColor = "${element.border_colour}";
        button.style.borderWidth = "${element.border_width}px";
      });
    }
  }, []);\n  `;
    importsCode = 'import React, { useState, useEffect } from \'react\';\n';
  }

  const code = `${importsCode}
function ${componentName} (){
  ${stateCode}${useEffectCode}return (
  ${jsxContent}
  );
};

export default ${componentName};`;

return code;
};