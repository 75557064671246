import React, {useRef, useEffect, useLayoutEffect, useState} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { useMeasure } from "react-use";
import { useEditorContext } from "../../../../../context/editor/editorcontext";

function CoreSwitch({stageRef, keyVal, shapeProps, setDomensions, isSelected, setSelectedId, elWidth, elHeight,  onSelect, onDragMove, onDragEnd, onTransform, onTransformEnd }) {

    const shapeRef = useRef();
    const parentRef = useRef();
    const trRef = useRef();

    const editorContext = useEditorContext();

    const [domDimensions, setDomDimensions] = useState({width:50, height:50})
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [loaded, setLoaded] = useState(false);
    const [loadedWidth, setLoadedWidth] = useState(null);
    const [loadedHeight, setLoadedHeight] = useState(null);
    
    const [switcher, setSwitcher] = useState(false);
    const [interactionMode, setInteractionMode] = useState(false);

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
        setDomensions({
            width: width,
            height: height
        })
    },[width, height])
  
    useEffect(() => {
      if (isSelected) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
    
    useEffect(()=>{
        if(loaded == false){
            setLoadedWidth(shapeProps.width);
            setLoadedHeight(shapeProps.height);
            setLoaded(true);
        }
    },[])

    useEffect(()=>{
        //console.log('calling')
        if(editorContext.activeElement?.id == shapeProps.id){
            console.log('triggering element',editorContext.activeElement.interaction_mode)
            setSelectedId(shapeProps.id);
            setInteractionMode(editorContext.activeElement.interaction_mode)
        }
    },[editorContext.activeElement]);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if(isSelected){
                // console.log('keys', key)
                if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
                    const props = {
                        id: shapeProps.id,
                        type: shapeProps.type,
                        grouped: shapeProps.grouped,
                        grouped_by: shapeProps.grouped_by,
                    }
                    editorContext.deleteElement(props);
                  }              
            }
        }
        //add a global event for `keydown`
        window.addEventListener('keydown', handleKeyDown)
       //clean up after component unmounted
       return () => window.removeEventListener('keydown', handleKeyDown)
     }, [isSelected])

     React.useEffect(() => {
        if(!isSelected) return
        const konvaContent = stageRef.content;
        const transformerElement = trRef.current.getNode().getCanvas()._canvas;
        
        transformerElement.style.position = 'absolute';
        transformerElement.style.top = '0';
        transformerElement.style.left = '0';
        transformerElement.style.zIndex = '9999';
        transformerElement.style.pointerEvents = 'none';

        
        konvaContent.appendChild(transformerElement);
    }, [isSelected]);
    
    return(
        <>
            <Group 
                key={keyVal}
                draggable={true} 
                onDragMove={onDragMove} 
                onDragEnd={onDragEnd} 
                x={shapeProps.xpos}
                y={shapeProps.ypos}
                width={parseFloat(elWidth)}
                height={parseFloat(elHeight)}
            >
                <Html divProps={{ style: { pointerEvents: interactionMode && isSelected ? "auto" : "none",}}} >
                    <div ref={dimensionRef} style={{
                        width: parseFloat(elWidth), 
                        height: parseFloat(elHeight), 
                        padding: 0,
                        margin: 0,
                    }}>
                        <div 
                            style={{
                                width:'100%', 
                                height:'100%', 
                                transition: 'ease-in-out',
                                transitionDuration: '.3s',
                                backgroundColor: switcher ? shapeProps.active_colour : shapeProps.disabled_colour,
                                padding: 10,
                                borderTopLeftRadius: shapeProps.border_radius_tl,
                                borderTopRightRadius: shapeProps.border_radius_tr,
                                borderBottomLeftRadius: shapeProps.border_radius_bl,
                                borderBottomRightRadius: shapeProps.border_radius_br,
                                boxSizing: 'border-box'
                            }} 
                            onClick={()=>setSwitcher(!switcher)}
                        > 
                            <div style={{
                                width: parseFloat(elHeight), 
                                height: '100%', 
                                backgroundColor: shapeProps.notch_colour,
                                transition: 'ease-in-out',
                                transitionDuration: '.3s',
                                borderRadius: 100,
                                transform: switcher ? `translateX(${domDimensions.width - parseFloat(elHeight) - 20}px)` :`translateX(${0}px)` ,
                            }}/>
                        </div>
                    </div>
                </Html>
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    width={domDimensions.width}
                    height={domDimensions.height}
                    fill={'transparent'} 
                    ref={shapeRef}  
                    id={shapeProps.id}
                    name="shape"
                />
            </Group> 

            { isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    anchorCornerRadius={100}
                    rotateEnabled={false}
                    enabledAnchors={[ "bottom-right", "bottom-center", "middle-right"]}
                    anchorSize={10}
                    padding={8}
                    onTransform={onTransform}
                    onTransformEnd={onTransformEnd}
                    rotationSnaps={[0, 90, 180, 270]}
                    rotationSnapTolerance={10}
                    keepRatio={true}
                />
            )} 
       </>
    )
}

export default CoreSwitch