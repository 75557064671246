
export default function executeGlobalAnim (anim, element, translatedX, animTrigger) {
    //console.log(anim)
//    switch (anim) {
//     case 'TransitionInLeftRight':
//         return translatedX + element.xpos;
//     case 'TransitionOutLeftRight':
//         return animTrigger.endValue
//     default:
//         break;
//    }

    // Base animation calculation
    const baseAnimation = (translatedX, element, endValue) => {
        // For grouped elements, return relative position
        if (element.grouped) {
            return element.xpos;
        }
        
        // For containers and ungrouped elements, return absolute position
        switch (anim) {
            case 'TransitionInLeftRight':
                return translatedX + element.xpos;
            case 'TransitionOutLeftRight':
                return endValue;
            default:
                return translatedX + element.xpos;
        }
    };

    return baseAnimation(translatedX, element, animTrigger.endValue);
}