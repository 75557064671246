import React, { createContext, useEffect, useState } from 'react';
import { supabase } from '../../supabase/supabaseclient';
import { prototypeSwitcher } from '../../components/player/utils/prototypeGenerator';
import {useSpringValue} from '@react-spring/konva';
import { 
    screenMacbookAir, 
    screenMacbookPro14, 
    screenMacbookPro16, 
    screenSmallDesktop,
    screenMediumDesktop,
    screenLargeDesktop,
    screenIphone16,
    screenIphone16Plus,
    screenIphone16Pro,
    screenIphone16ProMax,
    screenIphoneSE,
    screenSamsungGalaxyS24,
    screenIpadPro12_9,
    screenIpadPro11,
    screenIpadMini,
    screenSurfacePro8
} from '../editor/screens/screenobject.js';

export const PrototypeContext = createContext({
    getScreens: () => null,
    getFile: () => null,
    loadElements: () => null,
    loaded: null,
    screens: null,
    element: null,
    file: null,
    getScreenSize: () => null,
    currentRenderedScreen: null,
    setCurrentScreen: () => null,
    currentPrototypingElements: null,
    setCurrentRenderedElements: () => null,
    triggerPrototype: () => null,
    sortElements: () => null,
    animTrigger: null,
    setCurrentScreens: () => null,
})

export const PrototypeContextProvider = (props) => {

    const [screens, setScreens] = useState([]);
    const [element, setElement] = useState([]);
    const [elementLoader, setElementLoader] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [file, setFile] = useState();

    //What is currently rendered
    const [currentRenderedScreen, setCurrentRenderedScreen] = useState([]);
    const [currentPrototypingElements, setCurrentPrototypingElements] = useState([]);

    //trigger animation
    const [animTrigger, setAnimTrigger] = useState({});


////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////// File ////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

    const getFile = async(id) => {
        const {data, error} = await supabase
        .from('Files')
        .select()
        .eq('id', id)
 
        if(data){
         //console.log(data);
         setFile(data[0]);
        }
        
        if(error){
         console.log(error);
        }
    }

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Screens ///////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

    const getScreens = async(id) => {
       const {data, error} = await supabase
       .from('Screens')
       .select()
       .eq('file', id)

       if(data){
        //console.log(data);
        setScreens(data);

        const startScreen = data.find(screen => screen.isHome) || 
        data.reduce((oldest, current) => 
            oldest.created_at < current.created_at ? oldest : current
        );
        //set the start screen
        setCurrentRenderedScreen(startScreen);

       }
       
       if(error){
        console.log(error);
       }
    }

    const setCurrentScreens = () => {

    }


    const getScreenSize = (screenSize) => {
        switch (screenSize) {
            case 'iphone15':
                    return {width: 393, height: 852}
                break;        
            case 'Macbook Air':
                    const maScreenObj = screenMacbookAir(null, [0]);
                    return {width: maScreenObj.width, height: maScreenObj.height}
                break;
            case 'Macbook Pro 14"':
                    const mp14ScreenObj = screenMacbookPro14(null, [0]);
                    return {width: mp14ScreenObj.width, height: mp14ScreenObj.height}
                break;
            case 'Macbook Pro 16"':
                    const mp16ScreenObj = screenMacbookPro16(null, [0]);
                    return {width: mp16ScreenObj.width, height: mp16ScreenObj.height}
                break;
            case 'Small Desktop':
                    const sdScreenObj = screenSmallDesktop(null, [0]);
                    return {width: sdScreenObj.width, height: sdScreenObj.height}
                break;
            case 'Medium Desktop':
                    const mdScreenObj = screenMediumDesktop(null, [0]);
                    return {width: mdScreenObj.width, height: mdScreenObj.height}
                break;
            case 'Large Desktop':
                    const ldScreenObj = screenLargeDesktop(null, [0]);
                    return {width: ldScreenObj.width, height: ldScreenObj.height}
                break;
            case 'iPhone 16':
                    const ip16ScreenObj = screenIphone16(null, [0]);
                    return {width: ip16ScreenObj.width, height: ip16ScreenObj.height}
                break;
            case 'iPhone 16 Plus':
                    const ip16plusScreenObj = screenIphone16Plus(null, [0]);
                    return {width: ip16plusScreenObj.width, height: ip16plusScreenObj.height}
                break;
            case 'iPhone 16 Pro':
                    const ip16proScreenObj = screenIphone16Pro(null, [0]);
                    return {width: ip16proScreenObj.width, height: ip16proScreenObj.height}
                break;
            case 'iPhone 16 Pro Max':
                    const ip16promaxScreenObj = screenIphone16ProMax(null, [0]);
                    return {width: ip16promaxScreenObj.width, height: ip16promaxScreenObj.height}
                break;
            case 'iPhone SE':
                    const ipseScreenObj = screenIphoneSE(null, [0]);
                    return {width: ipseScreenObj.width, height: ipseScreenObj.height}
                break;
            case 'Samsung Galaxy S24':
                    const sgs24ScreenObj = screenSamsungGalaxyS24(null, [0]);
                    return {width: sgs24ScreenObj.width, height: sgs24ScreenObj.height}
                break;
            case 'iPad Pro 12.9"':
                    const ipadpro12ScreenObj = screenIpadPro12_9(null, [0]);
                    return {width: ipadpro12ScreenObj.width, height: ipadpro12ScreenObj.height}
                break;
            case 'iPad Pro 11"':
                    const ipadpro11ScreenObj = screenIpadPro11(null, [0]);
                    return {width: ipadpro11ScreenObj.width, height: ipadpro11ScreenObj.height}
                break;
            case 'iPad Mini':
                    const ipadminiScreenObj = screenIpadMini(null, [0]);
                    return {width: ipadminiScreenObj.width, height: ipadminiScreenObj.height}
                break;
            case 'Surface Pro 8':
                    const sp8ScreenObj = screenSurfacePro8(null, [0]);
                    return {width: sp8ScreenObj.width, height: sp8ScreenObj.height}
                break;
            default:
                break;
        }
    }

    const setCurrentScreen = (id) => {
        //console.log('new screen', id);
        setCurrentRenderedScreen(id);
    }

    const setCurrentRenderedElements = (item) => {
     //console.log(item);
     //console.log(currentRenderedScreen.id);
     const checkStateisUpdated = currentPrototypingElements.filter((i)  => i.screen == currentRenderedScreen.id);   
     setCurrentPrototypingElements([...checkStateisUpdated, item]);
    }


////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Load Elements /////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

const loadElements = async(id) => {
    if(loaded == false){
        await getTextFields(id);
        await getButtons(id);
        await getText(id);
        await getSwitch(id);
        await getImage(id);
        await getContainer(id);
        setLoaded(true);
        //setTimeout(()=>{console.log('loading elements', elementLoader)},1000);
    }
}

const sortElements = async() => {
    // sort elements to correct order
    // loop through elements
    console.log('array before sorting', elementLoader);
    const sorted = elementLoader.sort((a, b) => a.zIndex - b.zIndex);
    console.log('sorted array', sorted);
    //set elements for rendering
    setElement(sorted);
    //clear elementloader for performance
    setElementLoader([]);
}

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Text Field ////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

    const getTextFields = async(id) => {
        const {data, error} = await supabase
        .from('TextField')
        .select()
        .eq('file', id);
        
        if(data){
        // console.log(data);
        setElementLoader((currentElements) => [...currentElements, ...data]);
        }

        if(error){
         console.log(error);
        }
    }

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Button ////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

    const getButtons = async(id) => {
        const {data, error} = await supabase
        .from('Button')
        .select()
        .eq('file', id);
        
        if(data){
        // console.log(data);
        setElementLoader((currentElements) => [...currentElements, ...data]);
        }

        if(error){
         console.log(error);
        }
    }

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Text ////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

    const getText = async(id) => {
        const {data, error} = await supabase
        .from('Text')
        .select()
        .eq('file', id);
        
        if(data){
        // console.log(data);
        setElementLoader((currentElements) => [...currentElements, ...data]);
        }

        if(error){
        console.log(error);
        }
    }

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Switch ////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

const getSwitch = async(id) => {
    const {data, error} = await supabase
    .from('Switch')
    .select()
    .eq('file', id);
    
    if(data){
    // console.log(data);
    setElementLoader((currentElements) => [...currentElements, ...data]);
    }

    if(error){
    console.log(error);
    }
}

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Image ////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

const getImage = async(id) => {
    const {data, error} = await supabase
    .from('Image')
    .select()
    .eq('file', id);
    
    if(data){
    // console.log(data);
    setElementLoader((currentElements) => [...currentElements, ...data]);
    }

    if(error){
    console.log(error);
    }
}


////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Container /////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

const getContainer = async(id) => {
    const {data, error} = await supabase
    .from('Container')
    .select()
    .eq('file', id);
    
    if(data){
    // console.log(data);
    setElementLoader((currentElements) => [...currentElements, ...data]);
    }

    if(error){
    console.log(error);
    }
}

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////// Logic Switcher ////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////// 

const GlobalAnimTypes  = {
    TransitionInLeftRight: 'TransitionInLeftRight',
    TransitionOutLeftRight: 'TransitionOutLeftRight',
}

const triggerPrototype = (event, element) => {
    console.log('About to trigger prototype', event, element);
    // is there a trigger that is valid
    const triggerResult = prototypeSwitcher(event, element);
    console.log('This is trigger result', triggerResult);
    // if trigger is valid 
    // call sequencer
    if(triggerResult){
        
        sequencePrototype(element);
        return true;
    }else{
        return false;
    }
    
}

const sequencePrototype = (element) => {
   // get interaction from element
   // need to add checker to get interaction from element

   // trigger animation
   //console.log('in sequencer');
   switch (element.proto_interaction) {
    case 'Navigate to screen':
        console.log('setting trigger', currentRenderedScreen.id);
        //update current screen
        // setAnimTrigger({
        //     type: 'global',
        //     exitScreen: currentRenderedScreen.id,
        //     entranceScreen: element.navigate_to,
        //     originElementId: element.id,
        //     exitScreenAnimation: GlobalAnimTypes.TransitionOutLeftRight,
        //     entranceScreenAnimation: GlobalAnimTypes.TransitionInLeftRight,
        //     endValue: -element.width/2
        // })
         // First trigger exit animation
         setAnimTrigger({
            type: 'global',
            exitScreen: currentRenderedScreen.id,
            entranceScreen: element.navigate_to,
            originElementId: element.id,
            exitScreenAnimation: GlobalAnimTypes.TransitionOutLeftRight,
            entranceScreenAnimation: GlobalAnimTypes.TransitionInLeftRight,
            endValue: -element.width/2,
            sequence: 'exit'
        });

        // Wait for exit animation to complete
        setTimeout(() => {
            setAnimTrigger({
                type: 'global',
                exitScreen: currentRenderedScreen.id,
                entranceScreen: element.navigate_to,
                originElementId: element.id,
                exitScreenAnimation: GlobalAnimTypes.TransitionOutLeftRight,
                entranceScreenAnimation: GlobalAnimTypes.TransitionInLeftRight,
                endValue: -element.width/2,
                sequence: 'entrance'
            });
        }, 150); // Slightly longer than animation duration
        break;
   
    default:
        break;
   }

}


    const value = {
        getScreens,
        loadElements,
        getFile,
        screens,
        element,
        loaded,
        file,
        getScreenSize,
        currentRenderedScreen,
        setCurrentScreen,
        currentPrototypingElements,
        setCurrentRenderedElements,
        triggerPrototype,
        animTrigger,
        sortElements,
        setCurrentScreens
    }

    return ( 
        <PrototypeContext.Provider value={value}>
            {props.children}
        </PrototypeContext.Provider>
    )
}

export const usePrototypeContext = () => React.useContext(PrototypeContext);