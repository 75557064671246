import React, {useState, useEffect} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../dropdown.css';
import './interaction.css';
import '../../prototypemenu.css';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import { textfieldProtoOptions, buttonProtoOptions, textProtoOptions, switchProtoOptions, imageProtoOptions, containerProtoOptions } from '../../../../../context/editor/prototypeoptions/prototypeoptions';

function Interaction ({item, trigger, navigateTo, setInteraction, setShowScreens}){

    const editorContext = useEditorContext();
    const [inter, setInter] = useState(editorContext.activeElement.proto_interaction ? editorContext.activeElement.proto_interaction :  'Select Interaction');

    const renderOptions = (type) => {
        switch (type) {
            case 'textfield':
                return textfieldProtoOptions.interaction;
            case 'button':
                return buttonProtoOptions.interaction;
            case 'text':
                return textProtoOptions.interaction;
            case 'switch':
                return switchProtoOptions.interaction;
            case 'image':
                return imageProtoOptions.interaction;
            case 'container':
                return containerProtoOptions.interaction;
            default:
                break;
        }
    }
    
    useEffect(()=>{
        setInteraction(editorContext.activeElement.proto_interaction);
     },[editorContext.activeElement])

    const handle = (interaction) => {
        setInter(interaction);
        setInteraction(interaction);
        const props = {
            id: editorContext.activeElement.id,
            grouped: item.grouped,
            grouped_by: item.grouped_by,
            prototyping: editorContext.activeElement.prototyping,
            type: editorContext.activeElement.type,
            trigger: trigger,
            interaction: interaction,
            navigateTo: navigateTo,
        }
        editorContext.updateElementProto(props);
    }

    useEffect(()=>{
     if(inter == 'Navigate to screen'){
        setShowScreens(true);
     }else{
        setShowScreens(false);
     }
    },[inter])

     const handleInteraction = (e) => {
        switch (e) {
            case 'Navigate to screen':
                setInter(e);
                setShowScreens(true);
                handle(e);
                break;
            default:
                setInter(e);
                setShowScreens(false);
                handle(e);
                break;
        }
     }

    return(
        <div className='interactionContainer'>
           <div style={{marginRight: 20, color: '#fff'}}>
                <h3 className='prototype-menu-title'>Interaction</h3>
           </div>

            <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="prototype-menu-dropdown-trigger" aria-label="Customise options">
                    {inter}
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content className="prototype-menu-dropdown-content">
                    {
                        renderOptions(editorContext.activeElement.type).map((e)=>(
                            <DropdownMenu.Item className="prototype-menu-dropdown-item" onClick={()=>handleInteraction(e)}> 
                                {e} 
                            </DropdownMenu.Item>
                        ))
                    }
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
            </DropdownMenu.Root>
            
        </div>
        
    )
}

export default Interaction;