import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Stage, Layer, Rect, Text as KonvaText } from 'react-konva';
import Konva from 'konva';
import { fontSelection } from '../../fonts/fonts';
import Leftsp from '../../components/ui/leftsp/leftsp';
import Rightsp from '../../components/ui/rightsp/rightsp';
import CenterPanel from '../../components/ui/centerpanel/centerpanel';
import Canvas from '../../components/canvas/canvas';
import ToolBar from '../../components/ui/toolbar/toolbar';
import { useEditorContext } from '../../context/editor/editorcontext';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/auth/authcontext';
import { supabase } from '../../supabase/supabaseclient';
import LoadingScreen from '../../components/ui/loading/loadingscreen';


function getFileNameWithExt(event) {

    if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
      return;
    }
  
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
  
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);

    return `${event.target.files[0].name}.${ext}`  
}

function getFileName(event) {

    if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
      return;
    }
  
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);

    return `${fileName}.${ext}`  
}
  
function Editor(){

    const [fontArr, setFontArr] = useState(fontSelection);

    const editorContext = useEditorContext();
    const authContext = useAuthContext();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const { id } = useParams();
    const [loadedUser, setLoadedUser] = useState(false);
    const [loadedTeam, setLoadedTeam] = useState(false);
    const [teamSubscription, setTeamSubscription] = useState(null);
    const [teamInfo, setTeamInfo] = useState(null);

    useEffect(() => {
        const pasteHandler = async (e) => {
          e.preventDefault();
          
          // Only proceed if we have an active screen
          const activeScreen = editorContext.screens.find(screen => screen.isVisible);
          if (!activeScreen) return;
      
          const items = e.clipboardData?.items;
          if (!items) return;
      
          // Find the first image item
          const imageItem = Array.from(items).find(
            item => item.type.indexOf('image') !== -1
          );
      
          if (!imageItem) return;
      
          try {
            const blob = imageItem.getAsFile();
            if (!blob) return;
      
            // Create temporary object URL to get dimensions
            const tempUrl = URL.createObjectURL(blob);
            const img = new Image();
            
            img.onload = async () => {
              try {
                // Calculate dimensions (max 300px width/height while maintaining aspect ratio)
                const MAX_SIZE = 300;
                let width = img.width;
                let height = img.height;
                
                if (width > MAX_SIZE || height > MAX_SIZE) {
                  if (width > height) {
                    height = (height / width) * MAX_SIZE;
                    width = MAX_SIZE;
                  } else {
                    width = (width / height) * MAX_SIZE;
                    height = MAX_SIZE;
                  }
                }
      
                // First step: Create a blank image element with the correct dimensions
                const imageObj = {
                  user: authContext.user.id,
                  width: width,
                  height: height,
                  new_width: width,
                  new_height: height,
                  xpos: activeScreen.xpos + (activeScreen.width - width) / 2,
                  ypos: activeScreen.ypos + (activeScreen.height - height) / 2,
                  scaleX: 1,
                  scaleY: 1,
                  screen: activeScreen.id,
                  file: editorContext.currentFile,
                  name: `Pasted Image ${Date.now()}`
                };
      
                // Create the blank image element
                const newImageObj = await editorContext.addElement('image', imageObj);
          
                // Second step: Upload the image
                const filename = `pasted_${Date.now()}.png`;
                const { data: uploadData, error: uploadError } = await supabase.storage
                  .from('Rivveo_File_Images')
                  .upload(`images/${editorContext.currentFile}/${filename}`, blob, {
                    upsert: true,
                  });
      
                if (uploadError) {
                  console.error('Upload error:', uploadError);
                  return;
                }
      
                // Get the signed URL
                const { data: urlData, error: urlError } = await supabase.storage
                  .from('Rivveo_File_Images')
                  .createSignedUrl(uploadData.path, 172800);
      
                if (urlError) {
                  console.error('Error getting signed URL:', urlError);
                  return;
                }
      
                // Update the image element with the uploaded image data
                const updateProps = {
                  id: newImageObj.id,
                  type: 'image',
                  grouped: false,
                  grouped_by: null,
                  url: uploadData.path,
                  image_name: filename,
                  aspect_ratio: 'cover'
                };
      
                editorContext.updateElementCustomPanel(updateProps);
      
              } catch (err) {
                console.error('Error in image processing:', err);
              } finally {
                URL.revokeObjectURL(tempUrl);
              }
            };
      
            img.src = tempUrl;
          } catch (error) {
            console.error('Error handling pasted image:', error);
          }
        };
      
        // Add and remove event listener
        document.addEventListener('paste', pasteHandler);
        return () => document.removeEventListener('paste', pasteHandler);
      }, [editorContext, authContext]);

    useEffect(()=>{
        const loadUser = async() => {
            const { data, error } = await supabase
            .from('Users')
            .select()
            .eq('auth_id', authContext.session.user.id)
    
            if(data){
                console.log(data[0])
                authContext.setUserFn(data[0]);
                setLoadedUser(true);
            }
    
            if(error){
                console.log(error);
            }
          }

          //console.log('loading user', authContext.user, authContext.session)

          if(!authContext.user && authContext.session){
            console.log('loading user')
            loadUser();
          }
    },[authContext.user, authContext.session])

    useEffect(()=>{
        const getTeam = async() => {
            const { data:memberData, error:memberError } = await supabase
            .from('Members')
            .select(`
                team_id, 
                member_id, 
                Teams(id, team_name, company_name, number_of_seats, stripe_customer_id, status)
            `)
            .eq('member_id', authContext.user.id);
    
            if(memberData){
                // there is no team
                if(memberData.length == 0) { navigate('/signup/your-team/true'); return}
                console.log('member data', memberData)
                authContext.setTeamFn(memberData);
                setTeamInfo(memberData);
                setTeamSubscription(memberData[0].Teams.status);
                setLoadedTeam(true)
            }
    
            if(memberError){
                console.log(memberError);
            }
          }

          if(loadedUser){
            getTeam();
          }
    },[loadedUser]);

    useEffect(()=>{
        console.log(teamSubscription);
        // Check subscription status
        if(teamSubscription === null) return;

        if(teamSubscription == 'active' || teamSubscription == 'incomplete'){
            return;
        }else{
            console.log('team subscription navigating', teamSubscription);
            //Subscription is not valid 
            const company = teamInfo[0].Teams.company_name;
            const team = teamInfo[0].Teams.team_name;
            const team_id = teamInfo[0].Teams.id;
            navigate(`/signup/your-team/false/${company}/${team}/${team_id}`);
        }

    },[teamSubscription])

    useEffect(()=>{
        const loadFile = async() => {
            const { data, error } = await supabase
            .from('Files')
            .select()
            .eq('id', id);

            if(data){
                editorContext.setCurrentFileDetails(data[0].file_name, data[0].screen_size);
            }
    
            if(error){
                console.log(error);
            }
          }
          loadFile();
    },[])

    useEffect(()=>{
        // screen to be loaded from app
        // onload add screen
        if(loaded == false){
            setLoaded(true);
            console.log('load elements')
            editorContext.loadScreens(id);
            editorContext.setCurrentFileFn(id);
            editorContext.loadElements(id);           
        }
        
    },[]);

    useEffect(()=>{
        console.log('about to sort elements', editorContext.loaded)
        if(editorContext.loaded){
            // elements are loaded
            // sort elements
            editorContext.sortElements()
        }
    },[editorContext.loaded]);


    useEffect(()=>{
        //load screens - if none add one screen
        if(editorContext.currentFile && editorContext.currentFileScreenSize && editorContext.screensLoaded){
            // console.log('file id loaded', editorContext.currentFile);
            // console.log('screens', editorContext.screens);
            // console.log('screens loaded',editorContext.screensLoaded)
           if(editorContext.screens.length == 0){
            console.log('going to add screen', editorContext.currentFileScreenSize);
                editorContext.addScreen(editorContext.currentFileScreenSize);
           }
        }
    },[editorContext.currentFile, editorContext.currentFileScreenSize, editorContext.screensLoaded])

    useEffect(()=>{

        if(editorContext.screensLoaded && editorContext.newScreenFlag){
            const currentScreen = editorContext.screens.filter((i) => i.isVisible == true); 
            const newScreen = editorContext.screens[editorContext.screens.length-1]
            const oldProps = {
                id: currentScreen[0].id,
                isVisible: false
            }
            const props = {
                id: newScreen.id,
                isVisible: true
            }
    
            //console.log('this is the screen', screen);

            ////Important to stop infinite loop.
            editorContext.setNewScreenFlag(false);
            
            editorContext.setScreenVisible(props, oldProps);
        }

    },[editorContext.screens])

    useEffect(()=>{

    const loadFileFonts = async() => {
        let fileFonts = [];
        if(editorContext.element.length == 0) return;
        // Wait for all elements to be loaded
        // get fonts in file and load them
        editorContext.element.map(async(item)=>{
            const font = item.font;
            const fontExists = fileFonts.some(i => i === font)
            if(!font || fontExists) return;
            fileFonts.push(font);
            const fontFilter = fontArr.filter((i) => i.family == font);
            const loaded = await fontFilter[0].load();
            // Load the font itself
            const { fontFamily, ...otherInfo } = loaded.loadFont();
        });
    }
    loadFileFonts();
    },[editorContext.element])

    return(
        <Fragment>
                <>
                    <ToolBar/>
                    <Leftsp/>
                    <Rightsp fileId={id}/>
                    <Canvas fileId={id} />
                </>            
        </Fragment>
    );
}

export default Editor;