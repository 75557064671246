import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer, Line } from 'react-konva';
import { usePrototypeContext } from '../../context/prototype/prototypecontext';

function PrototypeScreen ({ x, y, offsetX, offsetY, width }) {

    const prototypeContext = usePrototypeContext();
    const [isSelected, setIsSelected] = useState(false );
    const trRef = useRef();
    const shapeRef = useRef();

    const onClickFn = () => {

    }

      
    return(
        <>
              {prototypeContext.screens ? prototypeContext.screens.map((screen) => (
                <>
                {prototypeContext.currentRenderedScreen.id == screen.id ? 
                 <> 
                    <Group name='screen' id={screen.id} x={0} y={0} width={screen.width}>
                        <Rect
                            key={screen.id}
                            offsetX={offsetX} 
                            offsetY={offsetY} 
                            x={x}
                            y={y} 
                            name='playerscreen'
                            width={screen.width}
                            height={screen.scroll_height ? screen.scroll_height: screen.height}
                            fill={screen.fill}
                            onClick={(e)=>onClickFn(screen)}
                            stroke={screen.isActive ? '#0090FF' : null}
                            strokeWidth={4}
                            strokeEnabled={true}
                            ref={shapeRef}
                        />

                    </Group>
                    </>
                    : null
                }
                </>  
              )):null}
        </>
    )
}

export default PrototypeScreen;