import React, {useState, useEffect} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../dropdown.css';
import '../../prototypemenu.css';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import { textfieldProtoOptions } from '../../../../../context/editor/prototypeoptions/prototypeoptions';

function SelectScreen ({item, interaction, trigger, setNavigateTo, showScreens, navigateTo}){

    const editorContext = useEditorContext();
    const [screen, setScreen] = useState('Select Screen');
    const [navi, setNavi] = useState(editorContext.activeElement.navigate_to ? editorContext.activeElement.navigate_to : null);

     const getScreen = () => {
         return editorContext?.screens?.filter((e)=>e.id == navi)
     }

     useEffect(()=>{
      setNavigateTo(editorContext.activeElement.navigate_to);
      if(showScreens && navi){
        console.log('setting navigate to screen')
        const screen = getScreen();
        console.log(screen)
        setScreen(screen ? screen[0].name : 'Select Screen');
      }
     },[editorContext.activeElement])

     const handle = (screen) => {
        setScreen(screen.name);
        setNavigateTo(screen.id);
        setNavi(screen.id);
        const props = {
            id: editorContext.activeElement.id,
            grouped: item.grouped,
            grouped_by: item.grouped_by,
            prototyping: editorContext.activeElement.prototyping,
            type: editorContext.activeElement.type,
            trigger: trigger,
            interaction: interaction,
            navigateTo: screen.id,
        }
        console.log(props);
        editorContext.updateElementProto(props);
    }

    return(
        <div className='interactionContainer'>
           <div style={{marginRight: 20, color: '#fff'}}>
                <h3 className='prototype-menu-title'>Navigate To</h3>
           </div>

            <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="prototype-menu-dropdown-trigger" aria-label="Customise options">
                    {screen}
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="prototype-menu-dropdown-content">
                   {
                        editorContext.screens.map((screen, i)=>(
                            <>
                             {
                                !screen.isVisible ?
                                <DropdownMenu.Item className="prototype-menu-dropdown-item" onClick={()=>handle(screen)}>
                                    {screen.name}
                                </DropdownMenu.Item>
                                :null
                             }
                            </>
                            

                        ))
                    }
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
            </DropdownMenu.Root>
            
        </div>
        
    )
}

export default SelectScreen;