import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group } from 'react-konva';
import { usePrototypeContext } from '../../context/prototype/prototypecontext';
import PlayerTextField from './elements/textfield/playertextfield'; 
import PlayerButton from './elements/button/playerbutton';
import PlayerText from './elements/text/playertext';
import PlayerSwitch from './elements/switch/playerswitch';
import PlayerImage from './elements/image/playerimage';
import PlayerContainer from './elements/container/playercontainer';
import { RepositionElements } from './utils/repositionElements';
import { Spring, animated, useSpring } from '@react-spring/konva';
import PlayerHeader from './header/playerheader';
import { EditorContext } from '../../context/editor/editorcontext';
import PrototypeScreen from '../screens/prototypescreen';
import './playercanvas.css';


function PlayerCanvas ({setScreenX, setScreenY, setScreenScale,  setClipScreenWidth, setClipScreenHeight }) {

    // render all elements
    // render home screen first (screen[0] for meantime)
    // render home screen and element on canvas

    const prototypeContext = usePrototypeContext();
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const canvasWidth = dimensions.width;  // Replace existing canvasWidth declaration
    const canvasHeight = dimensions.height;
    const stageRef = useRef();
    const [screenWidth, setScreenWidth] = useState(null);
    const [screenHeight, setScreenHeight] = useState(null);
    const [scale, setScale] = useState(0.8);
    const [readyToRender, setReadyToRender] = useState(false);
    const [CalcTranslation, setCalcTranslation] = useState(false);


    const newCanvasWidth = canvasWidth * (10 / (scale * 10)) -20;
    const newCanvasHeight = canvasHeight * (10 / (scale * 10)) -20;
    const x = newCanvasWidth/2;
    const y = newCanvasHeight/2;

    const [stageY, setStageY] = useState(0);

    
    // Add this useEffect for resize handling
    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(()=>{
        //   console.log('about to load prototype', screenWidth, prototypeContext.screens)
        // if(CalcTranslation && prototypeContext.screens){
        //     console.log('screen width', screenWidth)
        //     if(screenWidth > canvasWidth){
        //         console.log('screen is wider than tall');
        //         const newdimsW = screenWidth - (screenWidth - canvasWidth) - 150;
        //         setScale(newdimsW / screenWidth);
        //         setScreenScale(newdimsW / screenWidth);
        //     }else{
        //         console.log(' screen is taller than wide');
        //         const newdimsH = screenHeight - (screenHeight - canvasHeight) - 160;
        //         setScale(newdimsH / screenHeight);
        //         setScreenScale(newdimsH / screenHeight);
        //     }
        //     setReadyToRender(true);
        // }   
        
        if (CalcTranslation && prototypeContext.screens) {
            // Define header height and desired padding
            const HEADER_HEIGHT = 64; // Adjust based on your actual header height
            const PADDING = 40; // Padding on all sides
            
            // Available space accounting for header and padding
            const availableWidth = canvasWidth - (PADDING * 2);
            const availableHeight = canvasHeight - HEADER_HEIGHT - (PADDING * 2);
            
            // Calculate scale ratios for both dimensions
            const widthRatio = availableWidth / screenWidth;
            const heightRatio = availableHeight / screenHeight;
            
            // Use the smaller ratio to ensure screen fits in both dimensions
            const finalScale = Math.min(widthRatio, heightRatio);
            
            setScale(finalScale);
            setScreenScale(finalScale);
            setReadyToRender(true);
        }
    },[prototypeContext.screens, CalcTranslation])

    useEffect(()=>{
        if(prototypeContext.file){
            // get screen size
            const fileScreenSize = prototypeContext?.file?.screen_size;
            const screenSize = prototypeContext.getScreenSize(fileScreenSize);
            prototypeContext.getScreens(prototypeContext.file.id);


            console.log('this is the screens', prototypeContext.screens)
            setScreenWidth(screenSize.width);
            setScreenHeight(screenSize.height);
            setClipScreenWidth(screenSize.width);
            setClipScreenHeight(screenSize.height);
            setCalcTranslation(true);

            //setting for parent to calculate clipping mask
            //console.log('setting screenY', y - screenHeight/2)
            setScreenX((x  - (screenWidth/2)) * scale);
            setScreenY((y  - (screenHeight/2)) * scale);
        }
    },[prototypeContext.file])

    const handleMoveCanvas = (e) => {
        e.evt.preventDefault();
        const currentScreen = prototypeContext.currentRenderedScreen;

        //if the canvas hasn't been changed then don't scroll
        if(!currentScreen.scroll_height) return;
        //if the canvas isn't bigger than the preset height then don't scroll
        if(currentScreen.scroll_height < currentScreen.height) return;

        const playerScreen = stageRef.current.findOne('.playerscreen'); // Assuming you've given your screen this name
        const screenPos = playerScreen.absolutePosition();
        const screenBottom = screenPos.y + playerScreen.height() * stageRef.current.scaleY();

        const dy = e.evt.deltaY;

        const totalHeight = playerScreen.height() * stageRef.current.scaleY();
        const minY = -totalHeight + window.innerHeight/2;        //console.log('this is minY', minY);
        const maxY = 0;
        const y = Math.max(minY, Math.min(stageY - dy, maxY));
        setStageY(y);

    }

    return(
            <Stage 
                name='playercanvas'
                ref={stageRef}
                width={canvasWidth} 
                height={canvasHeight} 
                perfectDrawEnabled={false}
                x={0}
                y={stageY}
                scaleX={scale}
                scaleY={scale}
                style={{zIndex: 1}}
                onWheel={handleMoveCanvas}
            >   
                <Layer x={0} y={0}>
                    {/* <Rect x={x} y={y} width={50} height={50} fill={'#000'} /> */}
                    <PrototypeScreen x={x} y={y} offsetX={screenWidth/2} offsetY={screenHeight/2} width={screenWidth}/>
                    {/* <Rect x={x} y={y} offsetX={screenWidth/2} offsetY={screenHeight/2} width={screenWidth} height={screenHeight} fill={'#fff'}/> */}
                    {   readyToRender &&
                        prototypeContext.element.toReversed().map((item) => {
                            if(item.grouped){return;}
                            switch (item.type) {
                                case 'textfield':
                                    return <PlayerTextField element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'button':
                                    return <PlayerButton element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'text':
                                    return <PlayerText element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'switch':
                                    return <PlayerSwitch element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'image':
                                    return <PlayerImage element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'container':
                                    return <PlayerContainer element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                default:
                                break;
                            }
                        })
                    } 
                </Layer>
            </Stage>
    );
}

export default PlayerCanvas;