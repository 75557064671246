import { v4 as uuidv4 } from 'uuid';

export const textField = (screens, currentFile, element) => {
    const currentScreen = screens.filter((i) => i.isVisible == true);
    return{
        id: uuidv4(),
        name: 'Text Field '+ (element.length + 1),
        type: 'textfield',
        width: 360,
        height: 44,
        xpos: window.innerWidth,
        ypos: window.innerHeight,
        screen: currentScreen[0].id,
        file: currentFile,
        scaleX: 1,
        scaleY: 1,
        prototyping: false,
        proto_trigger: null,
        proto_interaction: null,
        proto_passdata: false,
        navigate_to: false,
        placeholder: 'text field',
        value: 'text field',
        font: 'Open Sans',
        font_size: 16,
        font_colour: '#000',
        fill: '#fff',
        border_colour: '#000',
        border_width: 1,
        border_radius_tl: 0,
        border_radius_tr: 0,
        border_radius_bl: 0,
        border_radius_br: 0,
        interaction_mode: false,
        zIndex: 0
    }
}

export const button = (screens, currentFile, element) => {
    const currentScreen = screens.filter((i) => i.isVisible == true);
    return{
        id: uuidv4(),
        name: 'Button '+ (element.length + 1),
        type: 'button',
        width: 300,
        height: 44,
        xpos: window.innerWidth,
        ypos: window.innerHeight,
        screen: currentScreen[0].id,
        file: currentFile,
        scaleX: 1,
        scaleY: 1,
        prototyping: false,
        proto_trigger: null,
        proto_interaction: null,
        proto_passdata: false,
        navigate_to: false,
        value: 'button',
        font: 'Open Sans',
        font_size: 16,
        font_colour: '#000',
        fill: '#fff',
        border_colour: '#000',
        border_width: 1,
        border_radius_tl: 0,
        border_radius_tr: 0,
        border_radius_bl: 0,
        border_radius_br: 0,
        interaction_mode: false,
        hover_fill: '#ccc',
        hover_border_colour: '#000',
        hover_border_width: 1,
        zIndex: 0,
        grouped: false,
        grouped_by: null,
    }
}

export const text = (screens, currentFile, element) => {
    const currentScreen = screens.filter((i) => i.isVisible == true);
    return{
        id: uuidv4(),
        name: 'Text '+ (element.length + 1),
        type: 'text',
        width: 300,
        height: 44,
        xpos: window.innerWidth,
        ypos: window.innerHeight,
        screen: currentScreen[0].id,
        file: currentFile,
        scaleX: 1,
        scaleY: 1,
        prototyping: false,
        proto_trigger: null,
        proto_interaction: null,
        proto_passdata: false,
        navigate_to: false,
        value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas odio felis, tempor ac neque eu, faucibus sagittis justo.',
        font: 'Open Sans',
        font_size: 16,
        font_colour: '#000',
        font_weight: 400,
        line_height: '1',
        letter_spacing: '0',
        text_alignment: 'center',
        zIndex: 0
    }
}

export const image = (screens, currentFile, element) => {
    const currentScreen = screens.filter((i) => i.isVisible == true);
    return{
        id: uuidv4(),
        name: 'Image '+ (element.length + 1),
        type: 'image',
        width: 500,
        height: 500,
        xpos: window.innerWidth,
        ypos: window.innerHeight,
        screen: currentScreen[0].id,
        file: currentFile,
        scaleX: 1,
        scaleY: 1,
        prototyping: false,
        proto_trigger: null,
        proto_interaction: null,
        proto_passdata: false,
        navigate_to: false,
        aspect_ratio: 'fill',
        url: null,
        image_name: null,
        border_radius_tl: 0,
        border_radius_tr: 0,
        border_radius_bl: 0,
        border_radius_br: 0,
        zIndex: 0 
    }
}

export const switcher = (screens, currentFile, element) => {
    const currentScreen = screens.filter((i) => i.isVisible == true);
    return{
        id: uuidv4(),
        name: 'Switch '+ (element.length + 1),
        type: 'switch',
        width: 300,
        height: 44,
        xpos: window.innerWidth,
        ypos: window.innerHeight,
        screen: currentScreen[0].id,
        file: currentFile,
        scaleX: 1,
        scaleY: 1,
        prototyping: false,
        proto_trigger: null,
        proto_interaction: null,
        proto_passdata: false,
        navigate_to: false,
        active_colour: '#32de84',
        disabled_colour: '#A9A9A9',
        notch_colour: '#fff',
        border_colour: '#000',
        border_width: 1,
        border_radius_tl: 0,
        border_radius_tr: 0,
        border_radius_bl: 0,
        border_radius_br: 0,
        interaction_mode: false,
        zIndex: 0,
        grouped: false,
        grouped_by: null,
    }
}


export const container = (screens, currentFile, element) => {
    const currentScreen = screens.filter((i) => i.isVisible == true);
    return{
        id: uuidv4(),
        name: 'Container '+ (element.length + 1),
        type: 'container',
        width: 350,
        height: 350,
        xpos: window.innerWidth,
        ypos: window.innerHeight,
        screen: currentScreen[0].id,
        file: currentFile,
        scaleX: 1,
        scaleY: 1,
        prototyping: false,
        proto_trigger: null,
        proto_interaction: null,
        proto_passdata: false,
        navigate_to: false,
        fill: '#fff',
        border_colour: '#000',
        border_width: 1,
        border_radius_tl: 0,
        border_radius_tr: 0,
        border_radius_bl: 0,
        border_radius_br: 0,
        interaction_mode: false,
        zIndex: 0,
        children: []
    }
}