import React, {useRef, useEffect, useLayoutEffect, useState, memo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { ImageIcon } from "@radix-ui/react-icons";
import { useMeasure } from "react-use";
import { supabase } from "../../../../../supabase/supabaseclient";
import { Portal } from 'react-konva-utils';
import { useEditorContext } from "../../../../../context/editor/editorcontext";
import { calcWidthHeight } from "../../../../../utils/editor/calcWidthHeight";
import { Item } from "@radix-ui/react-dropdown-menu";

function CoreImage({stageRef, keyVal, shapeProps, setDomensions, isSelected, setSelectedId, elWidth, elHeight,  onSelect, onDragMove, onDragEnd, onTransform, onTransformEnd }) {

    const shapeRef = useRef();
    const parentRef = useRef();
    const trRef = useRef();
    const imgRef = useRef();

    const editorContext = useEditorContext();

    const [domDimensions, setDomDimensions] = useState({width:50, height:50})
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [loaded, setLoaded] = useState(false);
    const [dataUrl, setDataUrl] = useState();
    const [imgLoaded, setImgLoaded] = useState(false);
    const [loadedWidth, setLoadedWidth] = useState(null);
    const [loadedHeight, setLoadedHeight] = useState(null);


    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
        setDomensions({
            width: width,
            height: height
        })
    },[width, height])
  
    useEffect(() => {
      if (isSelected) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
    
    useEffect(()=>{
        if(loaded == false){
            setLoadedWidth(shapeProps.width);
            setLoadedHeight(shapeProps.height);
            setLoaded(true);
        }
    },[])

    useEffect(()=>{
        if(editorContext.activeElement?.id == shapeProps.id){
            //console.log('triggering element', shapeProps.show_value )
            setSelectedId(shapeProps.id);
        }
    },[editorContext.activeElement]);



    useEffect(()=>{
        const loadImage = async() => {
            const { data, error } = await supabase.storage
            .from('Rivveo_File_Images')
            .createSignedUrl(shapeProps.url, 172800);
    
            if(data){
                console.log(data.signedUrl);
                setDataUrl(data.signedUrl);
                setImgLoaded(true)
            }

            if(error){
                console.log(error)
            }
        }
        if(shapeProps.url){
            loadImage();
        }
        
    },[shapeProps.url]);

    useEffect(()=>{
        console.log('data url', dataUrl);
    },[dataUrl])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(isSelected){
                // console.log('keys', key)
                if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
                    const props = {
                        id: shapeProps.id,
                        type: shapeProps.type,
                        grouped: shapeProps.grouped,
                        grouped_by: shapeProps.grouped_by,
                    }
                    editorContext.deleteElement(props);
                  }              
            }
        }
        //add a global event for `keydown`
        window.addEventListener('keydown', handleKeyDown)
       //clean up after component unmounted
       return () => window.removeEventListener('keydown', handleKeyDown)
     }, [isSelected])

     React.useEffect(() => {
        if(!isSelected) return
        const konvaContent = stageRef.content;
        const transformerElement = trRef.current.getNode().getCanvas()._canvas;
        
        transformerElement.style.position = 'absolute';
        transformerElement.style.top = '0';
        transformerElement.style.left = '0';
        transformerElement.style.zIndex = '9999';
        transformerElement.style.pointerEvents = 'none';

        
        konvaContent.appendChild(transformerElement);
    }, [isSelected]);

    

    return(
        <>
            <Group 
                ref={imgRef}
                key={keyVal}
                draggable={true} 
                onDragMove={onDragMove} 
                onDragEnd={onDragEnd} 
                x={shapeProps.xpos}
                y={shapeProps.ypos}
                width={parseFloat(elWidth)}
                height={parseFloat(elHeight)}
            >
                <Html divProps={{ style: { pointerEvents: "none",}}} >
                    <div ref={dimensionRef} style={{
                        width: parseFloat(elWidth), 
                        height: parseFloat(elHeight), 
                        padding: 0,
                        margin: 0,
                    }}>
                        { imgLoaded ? 
                            <img 
                                style={{
                                    width: '100%', 
                                    height: '100%', 
                                    borderTopLeftRadius: shapeProps.border_radius_tl,
                                    borderTopRightRadius: shapeProps.border_radius_tr,
                                    borderBottomLeftRadius: shapeProps.border_radius_bl,
                                    borderBottomRightRadius: shapeProps.border_radius_br,
                                    objectFit: shapeProps.aspect_ratio
                                }}
                                src={dataUrl}
                            />
                            :
                            <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flex: 1,
                                    backgroundColor: 'gray',
                                    borderRadius: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <ImageIcon width={60} height={60}/>
                            </div>
                        }
                        
                    </div>
                </Html>
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    width={domDimensions.width}
                    height={domDimensions.height}
                    fill={'transparent'} 
                    ref={shapeRef}  
                    id={shapeProps.id}
                    name="shape"
                />
            </Group> 

            { isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    anchorCornerRadius={100}
                    rotateEnabled={false}
                    enabledAnchors={[ "bottom-right", "bottom-center", "middle-right"]}
                    anchorSize={10}
                    padding={8}
                    onTransform={onTransform}
                    onTransformEnd={onTransformEnd}
                    rotationSnaps={[0, 90, 180, 270]}
                    rotationSnapTolerance={10}
                    keepRatio={true}
                />
            )} 
       </>
    )
}

export default memo(CoreImage);