import React, { useState, useEffect, useRef } from 'react';
import { useEditorContext } from '../../context/editor/editorcontext';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer, Line } from 'react-konva';
import { calcWidthHeight } from '../../utils/editor/calcWidthHeight';

function Screen ({ stageRef }) {

    const editorContext = useEditorContext();
    const [isSelected, setIsSelected] = useState(false );
    const trRef = useRef();
    const shapeRef = useRef();
    const absScale =  stageRef.current.getAbsoluteScale();


    const onClickFn = (e) => {
        // change screen active state
        editorContext.changeScreenActiveState(e);
        console.log('in OnClick', isSelected);
        console.log('in OnClick - about to set state', isSelected);
        setIsSelected(e.id);
        editorContext.setCanvasIsSelected(false);
        
    }

    const onTransform = (e) => {        

    }

    const onTransformEnd = (e, screen) => {
        const scaleY = e.target.attrs.scaleY;
        const finalHeight = calcWidthHeight(scaleY, e.target.height());

        const props = {
            id: screen.id,
            scroll_height: finalHeight,
        } 
        console.log(props);
        //updating screen db
        editorContext.updateScreenDb(props);
    }

    useEffect(() => {       
        if (isSelected !== false && editorContext.isCanvasSelected == false && !editorContext.activeElement) {
          console.log('showing transformer', isSelected, editorContext.isCanvasSelected, editorContext.activeElement)       
          trRef.current.nodes([shapeRef.current]);
          trRef.current.getLayer().batchDraw();
        }else if((isSelected && editorContext.isCanvasSelected) || editorContext.activeElement !== null){
            console.log('set false', isSelected, editorContext.isCanvasSelected, editorContext.activeElement)       
            setIsSelected(false);
            editorContext.changeScreenActiveState();
        }
        
    }, [isSelected, editorContext.isCanvasSelected, editorContext.activeElement]);
      
    return(
        <Layer>
              {editorContext.screens ? editorContext.screens.map((screen) => (
                <>
                {screen.isVisible ? 
                 <> 
                    <Group name='screen' id={screen.id} x={0} y={0} width={screen.width}>
                        <Rect
                            key={screen.id}
                            x={screen.xpos}
                            y={screen.ypos} 
                            width={screen.width}
                            height={screen.scroll_height ? screen.scroll_height: screen.height}
                            fill={screen.fill}
                            onClick={(e)=>onClickFn(screen)}
                            stroke={screen.isActive ? '#0090FF' : null}
                            strokeWidth={4}
                            strokeEnabled={true}
                            ref={shapeRef}
                        />
                        <KonvaText 
                            x={screen.xpos}
                            y={screen.ypos - 35} 
                            fontSize={24} 
                            text={screen.name}
                            fill={'#838CD1'}
                            onClick={(e)=>onClickFn(screen)}
                        />  
                        <Line
                            x={screen.xpos}
                            y={screen.ypos + screen.height}
                            width={10000}
                            height={2}
                            stroke={'#8DB3E5'}
                            points={[-6000, 0, 6000, 0]}
                            strokeWidth={1}
                            dash={[8,12]}
                        />      
                    </Group>
                        { isSelected && (
                            <Transformer
                                ref={trRef}
                                flipEnabled={false}
                                anchorCornerRadius={100}
                                rotateEnabled={false}
                                enabledAnchors={["bottom-center"]}
                                anchorSize={8}
                                padding={8}
                                onTransform={onTransform}
                                onTransformEnd={(e) => onTransformEnd(e, screen)}
                                rotationSnaps={[0, 90, 180, 270]}
                                rotationSnapTolerance={10}
                                keepRatio={true}
                                anchorStyleFunc={(anchor)=>{
                                    anchor.width(30);
                                    anchor.offsetX(15);
                                }}
                            />
                        )} 
                    </>
                    : null
                }
                </>  
              )):null}
        </Layer>
    )
}

export default Screen;