import React, {useRef, useEffect, useLayoutEffect, useState, useId} from "react";
import { Group, Layer, Line } from 'react-konva';
import CoreSwitch from "./core/coreswitch";
import { useEditorContext } from "../../../../context/editor/editorcontext";
import ContextMenu from "../../../ui/contextmenu/contextmenu";
import { rectInRect, ptInRect } from '../../utils/elementinscreen'
import { calcWidthHeight } from "../../../../utils/editor/calcWidthHeight";
import PrototypeMenu from "../../../ui/prototypemenu/prototypemenu";
import { ElementOverContainer } from "../../utils/elementovercontainer";
import { onDragMoveOS } from "../../utils/objectsnapping";

function Switch({item, stageRef}) {

    const [selectedId, setSelectedId] = useState(null);
    const editorContext = useEditorContext();
    const [domensions, setDomensions] = useState({x: 0, y: 0});

    const [hLines, setHLines] = useState([]);
    const [vLines, setVLines] = useState([]);

    const [width, setWidth] = useState(item.width);
    const [height, setHeight] = useState(item.height);
    const [xpos, setXpos] = useState(item.xpos);
    const [ypos, setYpos] = useState(item.ypos); 
    //all options for toolbar
    const [contextMenuOptions, setContextMenuOptions] = useState(
        {
            x: item.xpos,
            y: item.ypos,
        }
    )

    useEffect(()=>{
        // get screen with element id
        if(editorContext.isCanvasSelected){
            setSelectedId(null);
        }
    },[editorContext.isCanvasSelected]);
    
    return(
        <Group>
                <>
                    { editorContext.screens.some((e)=> e.isVisible === true && e.id == item.screen) ?
                    <>
                        <CoreSwitch 
                            stageRef={stageRef}
                            keyVal={item.id}
                            shapeProps={item}
                            setDomensions={setDomensions}
                            isSelected={item.id == selectedId && selectedId == editorContext.activeElement?.id}
                            setSelectedId={setSelectedId}
                            elWidth={width}
                            elHeight={height}
                            onSelect={(e) => {
                                //trigger transformer
                                setSelectedId(item.id);  
                                // set active element
                                editorContext.setCurrentActiveElement(item);
                                // update context menu
                                setContextMenuOptions({x: item.xpos, y: item.ypos})
                            }}
                            onDragMove={(e)=>{  
                                setSelectedId(item.id);
                                editorContext.setCurrentActiveElement(item);
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                    return;
                                }

                                e.cancelBubble = true;

                               const snapLines = onDragMoveOS(stageRef, e.target);
                               //console.log(snapLines)

                               if (snapLines.length > 0) {
                                setVLines(snapLines
                                  .filter(line => line.type === 'vertical')
                                  .map(line => ({
                                    points: [0, -6000, 0, 6000],
                                    x: line.x,
                                    y: 0,
                                    stroke: '#D138E5',
                                    strokeWidth: 1,
                                  }))
                                );
                                
                                setHLines(snapLines
                                  .filter(line => line.type === 'horizontal')
                                  .map(line => ({
                                    points: [-6000, 0, 6000, 0],
                                    x: 0,
                                    y: line.y,
                                    stroke: '#D138E5',
                                    strokeWidth: 1,
                                  }))
                                );
                                } else {
                                    // Clear lines if no snap points found
                                    setVLines([]);
                                    setHLines([]);
                                }

                                const x = e.target.attrs.x;
                                const y = e.target.attrs.y;
                                setXpos(x);
                                setYpos(y);
                            }}
                            onDragEnd={async(e)=>{
                               if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                return;
                               }

                               e.cancelBubble = true;

                               setVLines([]);
                               setHLines([]);
                            
                               //check if element is inside the screen
                               //get screen
                               const currentScreen = editorContext.screens.filter((i) => i.isVisible == true);
                               //get element 
                               const currentElement = e.target.attrs;

                               //check if element is over container
                               const isContainer = await ElementOverContainer(stageRef, editorContext.element, item);
    
                               // If element is in a container, get container's position
                               const containerPos = item.grouped ? 
                               editorContext.element.find(el => el.id === item.grouped_by) : 
                               null;

                               // Calculate absolute position
                               const absoluteX = item.grouped ? 
                               containerPos.xpos + currentElement.x : 
                               currentElement.x;

                               const absoluteY = item.grouped ? 
                               containerPos.ypos + currentElement.y : 
                               currentElement.y;

                               const r1 = { 
                                  left: currentScreen[0].xpos,
                                  top: currentScreen[0].ypos,
                                  right: currentScreen[0].xpos + currentScreen[0].width,
                                  bottom: currentScreen[0].ypos + currentScreen[0].height
                               }

                               const r2 = { 
                                   left: absoluteX,
                                   top: absoluteY,
                                   right: absoluteX + item.width,   
                                   bottom: absoluteY + item.height
                               }

                              const isElementinScreen =  rectInRect(r1, r2);
                               const x = e.target.attrs.x;
                               const y = e.target.attrs.y;
                               const props = {
                                    from: 'dragEnd',
                                    id: item.id,
                                    grouped: item.grouped,
                                    grouped_by: item.grouped_by,
                                    xpos: x,
                                    ypos: y,
                                    in_screen: isElementinScreen,
                                    type: 'switch'
                                }
                                //console.log(props);

                               if(isContainer && item.grouped_by !== isContainer.id){
                                // there is a container
                                // and the element is not already grouped by container
                                // console.log('adding to container');
                                // element is in container
                                editorContext.makeElementContainerChild(item, props, isContainer);
                               }else if(item.grouped && isContainer == false){
                                // element is grouped by a container
                                // but is currently over no container meaning 
                                // they've moved the element outside of the container
                                //console.log('removing from container');
                                editorContext.removeElementContainerChild(item, props);
                               }else{
                                // element doesn't meet the conditions above so just update the elements props
                                editorContext.updateElement(props);
                               }
                            }}
                            onTransform={(e)=>{
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                    return;
                                }

                                const x = e.target.attrs.x;
                                const y = e.target.attrs.y;
                                
                                // Use the rounded values directly
                                setWidth(calcWidthHeight(e.target.attrs.scaleX, e.target.attrs.width));
                                setHeight(calcWidthHeight(e.target.attrs.scaleY, e.target.attrs.height));

                            }}
                            onTransformEnd={(e)=>{
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                    return;
                                }

                                const scaleX = e.target.attrs.scaleX;
                                const scaleY = e.target.attrs.scaleY;
                                
                                // Calculate final dimensions using the utility function
                                const finalWidth = calcWidthHeight(scaleX, e.target.width());
                                const finalHeight = calcWidthHeight(scaleY, e.target.height());
                                
                                // Set the new dimensions
                                e.target.width(finalWidth);
                                e.target.height(finalHeight);

                                const props = {
                                    from: 'transformEnd',
                                    id: item.id,
                                    grouped: item.grouped,
                                    grouped_by: item.grouped_by,
                                    width: finalWidth,
                                    height: finalHeight,
                                    scaleX: 1,
                                    scaleY: 1,
                                    type: 'switch'
                                }
                                //console.log(props);
                                editorContext.updateElement(props)   
                                // e.target.scale({ x: 1, y: 1 });
                                e.target.setAttrs({scaleX: 1, scaleY:1});
                            }}
                        />
                            {
                                selectedId == editorContext.activeElement?.id &&
                                    <ContextMenu 
                                        contextMenuOptions={contextMenuOptions} 
                                        item={item}
                                        isSelected={item.id === selectedId}
                                        domensions={domensions}
                                        xpos={xpos} 
                                        ypos={ypos} 
                                        width={width} 
                                        height={height}
                                    /> 
                            }

                            {                            
                                selectedId == editorContext.activeElement?.id && editorContext.activeElement?.prototyping 
                                && !editorContext?.isCanvasSelected && !editorContext?.aScreenIsActive &&
                                    <PrototypeMenu item={item} xpos={xpos} ypos={ypos} width={width} height={height} /> 
                            }
                    </>
                        :
                        null
                    }
                </>

                { item.id == selectedId && selectedId == editorContext.activeElement?.id && (
                    <>
                        {hLines.map((item, i) => (
                            <Line key={i} {...item} />
                        ))}
                        {vLines.map((item, i) => (
                            <Line key={i} {...item} />
                        ))}
                    </>
                )
                } 
        </Group>
     
    )

}

export default Switch;