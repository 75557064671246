import { getAvailableFonts } from "@remotion/google-fonts";
import React, { useCallback, useEffect, useState } from "react";
import { fontSelection } from "../../../../../fonts/fonts"; 
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import './fontpicker.css';
import '../elementpanels/elementpanel.css';

const FontPicker = ({item, setFont, onChange}) => {

  const [fontArr, setFontArr] = useState(fontSelection);
  const [fontSelect, setFontSelect] = useState(item.font);

  useEffect(()=>{
    setFontSelect(item.font);
  },[item])

//   useEffect(()=>{
//     const loadAllFonts = async() => {
//         fontArr.map(async(item)=>{
//             const loaded = await item.load();
//             // Load the font itself
//             const { fontFamily, ...otherInfo } = loaded.loadFont();
//         })
//     }

//     loadAllFonts();
    
//   },[])

 
  return (
    <div>
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <div className="element-panel-dropdown-trigger" >
                    <p>Font</p>
                    <button aria-label="Customise options" style={{fontFamily: fontSelect}}>
                        {fontSelect}
                        <ChevronDownIcon color="#777FBD" width={16} height={16}/>
                    </button>
                </div>
                
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="element-panel-dropdown-content">

                    {fontArr.map((f) => {
                        return (
                            <DropdownMenu.Item className="DropdownMenuItem" style={{fontFamily: f.family}} key={f.importName} value={f.importName} onClick={()=>{setFontSelect(f.family); setFont(f.family); onChange(f.family)}}>
                            {f.family}
                            </DropdownMenu.Item>
                        );
                    })}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    </div>
  );
};

export default FontPicker