import React, { useEffect, useState } from "react"
import * as Popover from '@radix-ui/react-popover';
import { HomeIcon } from '@radix-ui/react-icons';
import { useEditorContext } from "../../../../../context/editor/editorcontext";
import { HexColorPicker, HexColorInput } from "react-colorful";
import '../elementpanels/elementpanel.css';
import './screenpanel.css';

function ScreenPanel ({item}){

    const editorContext = useEditorContext();
    const [home, setHome] = useState(item.isHome);

    const [fill, setFill] = useState(item.fill);

    useEffect(()=>{
     setFill(item.fill);
    },[item])

    const updateElement = (fill) => { 
            const props = { 
                id: item.id,
                fill: fill
            }
            editorContext.updateScreenPanel(props);
    }

    const handlePanel = (fill) => {
        updateElement(fill);
    }

    const handleSettingScreenHome = () => { 
        // if the screen is currently home then set to false
        // if the screen is currently false then set to true
        const vis = editorContext.screens.find(e => e.isVisible === true);
        const currentHome = editorContext.screens.find(e => e.isHome == true);
        console.log(vis, currentHome);
        if(home || vis.isHome){
            // current screen isHome is set to true so set to false
            setHome(false);
            editorContext.setScreenAsHome(vis, false, currentHome);
        }else if (!home || !vis.isHome){
            // current screen isHome is set to true so set to false
            setHome(true);
            editorContext.setScreenAsHome(vis, true, currentHome);
        }

    }


    useEffect(()=>{

        if(editorContext.screens){        
            const vis = editorContext.screens.find(e => e.isVisible === true);
            if(vis){
                setHome(vis.isHome);
            }else{
                setHome(editorContext.screens[0].isHome);
            }
        }
        

    },[editorContext.screens])

    return(
        <div className="textfieldpanelcontainer element-panel-container">
            <div className="element-panel-title">
                <p>{item.name}</p>
            </div> 
            <div className="element-panel-section">
                <button className='set-screen-home' onClick={()=>handleSettingScreenHome()} style={{
                    backgroundColor: home ? '#8DB3E5' : '#292B42',
                    color: home ? '#292B42' : '#8DB3E5'
                }}>
                    {home ? <>home <HomeIcon/></> :  <>set home <HomeIcon/></>}
                </button>    
            </div>       
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Fill</p>
                            <div>
                                <div style={{background: fill}}/>
                                <p>{fill}</p>
                            </div>
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={fill} onChange={(e)=>handlePanel(e)}/>
                            <HexColorInput color={fill} onChange={(e)=>handlePanel(e)} />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    )
}

export default ScreenPanel