import React, { useState } from 'react';
import { Maximize2, Minimize2 } from 'lucide-react';
import CodePanel from './components/CodePanel';
import './CodeEditor.css';

const ResizableCodePanel = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`resizable-panel ${isExpanded ? 'expanded' : ''}`}>
      <CodePanel />
    </div>
  );
};

export default ResizableCodePanel;