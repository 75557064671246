import React, { useState, useEffect, useRef, Fragment } from 'react';
import { supabase } from '../../../supabase/supabaseclient';
import { TiTick } from 'react-icons/ti';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/auth/authcontext';
import './checkoutFinished.css'

function CheckoutFinished(){

    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkSession = async() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const { data, error } = await supabase.functions.invoke('stripe-session-check', {
            body: {
                sessionId: sessionId,
            }
        })
        //const session = await response.json();
      // Handle the session according to its status
        if (data.status == 'open') {
          // Remount embedded Checkout
          navigate(-1);
        } else if (data.status == 'complete') {
            // update check to DB
          setSuccess(true);
          setLoading(true);
        }else{
          setSuccess(false);
          setLoading(true);
        }
      }

    const handleCheckout = () => {
        navigate('/dashboard');
    }

    useEffect(()=>{
        checkSession();
    },[])

    return (
        <div className='signup-main'>
            <div className='signup-header-container'>
                <h1 className="signup-header">Rivveo <span>BETA</span></h1>
            </div>
            <div className='signup-box'>
                
                <div className='signup-header-container'>
                    {
                        success ? 
                            <h1 className='signup-box-header'>You're all set!</h1>
                        :

                        loading ?

                            <h1 className='signup-box-header'>There's been an issue</h1>
                        :
                            <h1 className='signup-box-header'>Working some magic</h1>
                    }
                   
                </div>

                {
                    success ?

                    <div className='checkout-payment-succesful'>
                        <TiTick width={30} height={30}/>
                        <h1>Your payment was successful</h1>
                    </div>

                    :

                    loading ? 

                    <div className='checkout-payment-succesful'>
                        <h1>There was an issue with your payment</h1>
                    </div>

                    :

                    <div className='checkout-payment-succesful'>
                        <h1>Loading</h1>
                    </div>
                }

                

                <button className='signup-box-button' onClick={()=>handleCheckout()}>
                    Go to dashboard                
                </button>
            </div>
            
        </div>

    )

}

export default CheckoutFinished;