import { v4 as uuidv4 } from 'uuid';

const calculateXY = () => {
    const scale = 0.5
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;
    const newCanvasWidth = canvasWidth * (10 / (scale * 10)) -20;
    const newCanvasHeight = canvasHeight * (10 / (scale * 10)) -20;
    const x = newCanvasWidth/2;
    const y = newCanvasHeight/2

    return {x:x, y:y};
}

////////////
/// Desktop
////////////

export const screenMacbookAir = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Macbook Air - '+ (screens.length + 1),
        type: 'desktop',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1280,
        height: 832,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenMacbookPro14 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Macbook Pro 14" - '+ (screens.length + 1),
        type: 'desktop',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1512,
        height: 982,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenMacbookPro16 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Macbook Pro 16" - '+ (screens.length + 1),
        type: 'desktop',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1728,
        height: 1117,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenSmallDesktop = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Small Desktop - '+ (screens.length + 1),
        type: 'desktop',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1200,
        height: 1024,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenMediumDesktop = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Medium Desktop - '+ (screens.length + 1),
        type: 'desktop',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1440,
        height: 1024,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenLargeDesktop = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Large Desktop - '+ (screens.length + 1),
        type: 'desktop',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1920,
        height: 1024,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

////////////
/// Mobile
////////////
export const screenIphone16 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPhone 16 - '+ (screens.length + 1),
        type: 'mobile',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 393,
        height: 852,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenIphone16Plus = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPhone 16 Plus - '+ (screens.length + 1),
        type: 'mobile',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 430,
        height: 932,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenIphone16Pro = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPhone 16 Pro - '+ (screens.length + 1),
        type: 'mobile',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 402,
        height: 874,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenIphone16ProMax = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPhone 16 Pro Max - '+ (screens.length + 1),
        type: 'mobile',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 440,
        height: 956,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenIphoneSE = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPhone SE - '+ (screens.length + 1),
        type: 'mobile',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 320,
        height: 568,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenSamsungGalaxyS24 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Samsung Galaxy S24 - '+ (screens.length + 1),
        type: 'mobile',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 360,
        height: 780,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

////////////
/// Tablet
////////////

export const screenIpadPro12_9 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPad Pro 12.9" - ' + (screens.length + 1),
        type: 'tablet',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 1024,
        height: 1366,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenIpadPro11 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPad Pro 11" - ' + (screens.length + 1),
        type: 'tablet',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 834,
        height: 1194,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenIpadMini = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'iPad Mini - '+ (screens.length + 1),
        type: 'tablet',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 744,
        height: 1134,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}

export const screenSurfacePro8 = (fileId, screens) => {
    const xy = calculateXY();
    return {
        id: uuidv4(),
        name: 'Surface Pro 8 - '+ (screens.length + 1),
        type: 'tablet',
        file: fileId,
        isScreen: true,
        xpos: xy.x,
        ypos: xy.y,
        width: 744,
        height: 1134,
        fill: '#fff',
        isActive: false,
        isVisible: false,
        in_screen: false,
        isHome: false,
    }
}