import React, { createContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import { supabase } from '../../supabase/supabaseclient';
import { useAuthContext } from '../auth/authcontext';
import { 
    screenMacbookAir, 
    screenMacbookPro14, 
    screenMacbookPro16, 
    screenSmallDesktop,
    screenMediumDesktop,
    screenLargeDesktop,
    screenIphone16,
    screenIphone16Plus,
    screenIphone16Pro,
    screenIphone16ProMax,
    screenIphoneSE,
    screenSamsungGalaxyS24,
    screenIpadPro12_9,
    screenIpadPro11,
    screenIpadMini,
    screenSurfacePro8
} from './screens/screenobject';
import { textField, button, text, image, switcher, container } from './elements/elements';

export const EditorContext = createContext({
    screens: null,
    addScreen: () => null,
    changeScreenActiveState: () => null,
    deactivateAllScreens: () => null,
    setCanvasIsSelected: () => null,
    isCanvasSelected: null,
    element: null,
    setElement: () => null,
    addElement: () => null,
    setCurrentActiveElement: () => null,
    activeElement: null,
    setScreenVisible: () => null,
    updateElement: () => null,
    aScreenIsActive: null,
    latestContextMenu: null,
    updateLatestContextMenu: () => null,
    setElementPrototyping: () => null,
    setElementInteractionMode: () => null,
    setCurrentFileFn: () => null,
    currentFile: null,
    loadElements: () => null,
    loadScreens: () => null,
    updateElementProto: () => null,
    updateElementCustomPanel: () => null,
    updateElementStyling: () => null,
    loaded: null,
    sortElements: () => null,
    updateElementZindex: () => null,
    deleteElement: () => null,   
    setCurrentFileDetails: () => null,
    currentFileName: null, 
    currentFileScreenSize: null,
    resetAppStates: () => null,
    screensLoaded: null,
    newScreenFlag: null,
    setNewScreenFlag: () => null,
    makeElementContainerChild: () => null,
    removeElementContainerChild: () => null,
    updateChildZindexDb: () => null,
    updateScreenDb: () => null,
    setScreenAsHome: () => null,
    updateScreenPanel: () => null,
})

export const EditorContextProvider = (props) => {

    const authContext = useAuthContext();

    const [screens, setScreens] = useState([]);
    const [screensLoaded, setScreenLoaded] = useState(false);
    const [newScreenFlag, setNewScreenFlag] = useState(false);

    const [aScreenIsActive, setAScreenIsActive] = useState(false);

    // is the canvas selected
    const [isCanvasSelected, setIsCanvasSelected] = useState(true);

    // what is currently the active element
    const [activeElement, setActiveElement] = useState(null);

    // Latest x,y of the context menu used to calculate prototype menu
    const [latestContextMenu, setLatestContextMenu] = useState({x:0, y:0});

    // elements
    const [element, setElement] = useState([]);

    //elements loader
    const [elementLoader, setElementLoader] = useState([]);

    // track current file open
    const [currentFile, setCurrentFile] = useState(null);
    const [currentFileName, setCurrentFileName] = useState(null);
    const [currentFileScreenSize, setCurrentFileScreenSize] = useState(null);

    //load element once
    const [loaded, setLoaded] = useState(false);
    
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// SCREENS /////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

    const updateScreenPanel = async(props) => {
        // update screen locally
        setScreens((currentScreens) => currentScreens.map((scrn)=>{
            if(scrn.id == props.id){
                return {
                    ...scrn,
                    fill: props.fill
                }
            }
            return scrn
        }))
        // update DB

        const { data, error } = await supabase
        .from('Screens')
        .update({
            fill: props.fill,
        })
        .eq('id', props.id);

        if(error){
            console.log(error);
        }
    }

    const setScreenAsHome = async(scrn, condition, currentscrn) => {
        console.log(scrn, currentscrn);
        if(!scrn) return;
        if(condition == true){
            const { data, error } = await supabase
            .from('Screens')
            .update({
                isHome: true,
            })
            .eq('id', scrn.id);

            if(error){
                console.log(error);
            }

            setScreens((CurrentScreens) => CurrentScreens.map((screen)=>{
                if(screen.id  === scrn.id){
                    return{
                        ...screen,
                        isHome: true,
                    }
                }
                return screen;
            }))
        }else if(condition == false){
            const { data, error } = await supabase
            .from('Screens')
            .update({
                isHome: false,
            })
            .eq('id', scrn.id);

            if(error){
                console.log(error);
            }
            setScreens((CurrentScreens) => CurrentScreens.map((screen)=>{
                if(screen.id  === scrn.id){
                    return{
                        ...screen,
                        isHome: false,
                    }
                }
                return screen;
            }))
        }
 
        // if there is not other screen with isHome set to true return
        if(!currentscrn)return;

        //if currentscrn set isHome to False
        const { data:currentScrnData, error:currentScrnError } = await supabase
        .from('Screens')
        .update({
            isHome: false,
        })
        .eq('id', currentscrn.id);

        if(currentScrnError){
            console.log(currentScrnError);
        }

        setScreens((CurrentScreens) => CurrentScreens.map((screen)=>{
            if(screen.id  === currentscrn.id){
                return{
                    ...screen,
                    isHome: false,
                }
            }
            return screen;
        }))
    }

    const updateScreenDb = async(props) => {
        const { data, error } = await supabase
        .from('Screens')
        .update({
            scroll_height: props.scroll_height,
        })
        .eq('id', props.id);

        if(data){
        // console.log(data)
            //setScreens(data);
        }

        if(error){
            console.log(error);
        }
    }

    const updateOldScreenVisibleDB = async(props) => {
        // load elements 
        const { data, error } = await supabase
        .from('Screens')
        .update({
            isVisible: props.isVisible,
        })
        .eq('id', props.id);

        if(data){
        // console.log(data)
            //setScreens(data);
        }

        if(error){
            console.log(error)
        }
    }

    const updateNewScreenVisibleDB = async(props) => {
        // load elements 
        //console.log('update new visible db', props)
        const { data, error } = await supabase
        .from('Screens')
        .update({
           isVisible: props.isVisible,
        })
        .eq('id', props.id);

        if(data){
           //console.log(data)
            // setScreens(data);
        }

        if(error){
            console.log(error)
        }
    }
    
    const setScreenVisible = async(props, oldProps) => {

        //console.log('this is props', props, oldProps)
        //set current screen to true
        setScreens(
            screens.map((screen) => {
                return{
                    ...screen,
                    isVisible: screen.id == props.id ? true : false
                }
            })
        )

        if(screens.length > 0 && oldProps){
            await updateNewScreenVisibleDB(props);
            await updateOldScreenVisibleDB(oldProps);
        }else{
            await updateNewScreenVisibleDB(props);
        }
       
    }
    

    const changeScreenActiveState = (e) => {
        ///// change screen states
        // if only recieve canvas event and no screen event == false
        // if canvas event and screen event = ignore canvas 
        if(e){
            // a screen is active
            // deactivate all elements
            const id = e.id;
            setScreens(
                screens.map((screen) => {
                    return{
                        ...screen,
                        isActive: screen.isActive ? false : screen.id === id
                    }
                })
            )
            setAScreenIsActive(true);
            setActiveElement(null);
        }else {
            setScreens(
                screens.map((screen) => {
                    return{
                        ...screen,
                        isActive: false
                    }
                })
            )
            setAScreenIsActive(false);
        }       
    }

    const deactivateAllScreens = () => {
        setScreens(
            screens.map((screen) => {
                return{
                    ...screen,
                    isActive: false
                }
            })
        )
    }
    

    const loadScreens = async(id) => {
        // load elements 
        const { data, error } = await supabase
        .from('Screens')
        .select()
        .eq('file', `${id}`)

        if(data){
           // console.log(data)
            setScreens(data);
            setScreenLoaded(true);
        }

        if(error){
            console.log(error)
        }
    }

    const addScreenDB = async(obj) => {
        const {data, error}  = await supabase
        .from('Screens')
        .insert({
            file: obj.file,
            name: obj.name, 
            width: obj.width,
            height: obj.height,
            xpos: obj.xpos,
            ypos: obj.ypos,
            fill: obj.fill
        })
        .select();

        if(data){
            return data;
        }

        if(error){
            console.log(error);
        }
    }

    const addScreen = (screen) => {
        // add screen to file
        // sync with Supabase
        //console.log('adding', screen)
        switch (screen) {
            case 'Macbook Air':
                const maScreenObj = screenMacbookAir(currentFile, screens);
                addScreenDB(maScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    maScreenObj.id = res[0].id;
                    //if first screen set as visible
                    maScreenObj.isVisible = screens.length == 0 ? true : false;

                    setScreens([...screens, maScreenObj]); 
                    setNewScreenFlag(true);      
                });
                break;
            case 'Macbook Pro 14"':
                const mp14ScreenObj = screenMacbookPro14(currentFile, screens);
                addScreenDB(mp14ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    mp14ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    mp14ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, mp14ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'Macbook Pro 16"':
                const mp16ScreenObj = screenMacbookPro16(currentFile, screens);
                addScreenDB(mp16ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    mp16ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    mp16ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, mp16ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'Small Desktop':
                const sdScreenObj = screenSmallDesktop(currentFile, screens);
                addScreenDB(sdScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    sdScreenObj.id = res[0].id;
                    //if first screen set as visible
                    sdScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, sdScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'Medium Desktop':
                const mdScreenObj = screenMediumDesktop(currentFile, screens);
                addScreenDB(mdScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    mdScreenObj.id = res[0].id;
                    //if first screen set as visible
                    mdScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, mdScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'Large Desktop':
                const ldScreenObj = screenLargeDesktop(currentFile, screens);
                addScreenDB(ldScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ldScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ldScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ldScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPhone 16':
                const ip16ScreenObj = screenIphone16(currentFile, screens);
                addScreenDB(ip16ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ip16ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ip16ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ip16ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPhone 16 Plus':
                const ip16plusScreenObj = screenIphone16Plus(currentFile, screens);
                addScreenDB(ip16plusScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ip16plusScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ip16plusScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ip16plusScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPhone 16 Pro':
                const ip16proScreenObj = screenIphone16Pro(currentFile, screens);
                addScreenDB(ip16proScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ip16proScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ip16proScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ip16proScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPhone 16 Pro Max':
                const ip16promaxScreenObj = screenIphone16ProMax(currentFile, screens);
                addScreenDB(ip16promaxScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ip16promaxScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ip16promaxScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ip16promaxScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPhone SE':
                const ipseScreenObj = screenIphoneSE(currentFile, screens);
                addScreenDB(ipseScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ipseScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ipseScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ipseScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'Samsung Galaxy S24':
                const sgs24ScreenObj = screenSamsungGalaxyS24(currentFile, screens);
                addScreenDB(sgs24ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    sgs24ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    sgs24ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, sgs24ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPad Pro 12.9"':
                const ipadpro12ScreenObj = screenIpadPro12_9(currentFile, screens);
                addScreenDB(ipadpro12ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ipadpro12ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ipadpro12ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ipadpro12ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPad Pro 11"':
                const ipadpro11ScreenObj = screenIpadPro11(currentFile, screens);
                addScreenDB(ipadpro11ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ipadpro11ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ipadpro11ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ipadpro11ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'iPad Mini':
                const ipadminiScreenObj = screenIpadMini(currentFile, screens);
                addScreenDB(ipadminiScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    ipadminiScreenObj.id = res[0].id;
                    //if first screen set as visible
                    ipadminiScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, ipadminiScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            case 'Surface Pro 8':
                const sp8ScreenObj = screenSurfacePro8(currentFile, screens);
                addScreenDB(sp8ScreenObj).then((res)=>{
                    //console.log('db data', res[0]);
                    // update id to DB id 
                    sp8ScreenObj.id = res[0].id;
                    //if first screen set as visible
                    sp8ScreenObj.isVisible = screens.length == 0 ? true : false;
                    setScreens([...screens, sp8ScreenObj]);
                    setNewScreenFlag(true);
                });
                break;
            default:
                break;
        }
    }


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// MISC ////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

    const resetAppStates = () => {
        setElement([]);
        setScreens([]);
        setScreenLoaded(false);
        setActiveElement(null);
        setCurrentFile(null);
        setCurrentFileName(null);
        setCurrentFileScreenSize(null);
        setLoaded(false);
    }

    const updateContainerChildProtoDb = async(props) => {
        // If element is grouped, first update the container's child
        const container = element.find(el => el.id === props.grouped_by);
        if(!container)return;
        
            const updatedChildren = container.children.map(child => 
                child.id === props.id ? {
                    ...child,
                    prototyping: props.prototyping,
                    proto_trigger: props.trigger,
                    proto_interaction: props.interaction,
                    proto_passdata: props.passdata,
                    navigate_to: props.navigateTo
                } : child
            );
            // Save to database
            const { error } = await supabase
            .from('Container')
            .update({ children: updatedChildren })
            .eq('id', props.grouped_by);

            if(error) {
                console.log(error);
            }
    }

    const updateLocalProto = (props) => {   
        // Update local state - both grouped and ungrouped versions
        setElement(prevElements =>
            prevElements.map((el) => {
                if (el.id === props.grouped_by) {
                    return {
                        ...el,
                        children: el.children.map(child =>
                            child.id === props.id ? {
                                ...child,
                                prototyping: props.prototyping ? props.prototyping : element.prototyping,
                                proto_trigger: props.trigger ? props.trigger : element.proto_trigger,
                                proto_interaction: props.interaction ? props.interaction : element.proto_interaction,
                                proto_passdata: props.passdata ? props.passdata : element.proto_passdata,
                                navigate_to: props.navigateTo ? props.navigateTo : element.navigate_to
                            } : child
                        )
                    };
                }
                if (el.id === props.id) {
                    return {
                        ...el,
                        prototyping: props.prototyping ? props.prototyping : element.prototyping,
                        proto_trigger: props.trigger ? props.trigger : element.proto_trigger,
                        proto_interaction: props.interaction ? props.interaction : element.proto_interaction,
                        proto_passdata: props.passdata ? props.passdata : element.proto_passdata,
                        navigate_to: props.navigateTo ? props.navigateTo : element.navigate_to
                    };
                }
                return el;
            })
        );

        if(props.grouped){
            updateContainerChildProtoDb(props);
        }
    }

    const updateContainerChildDb = async(container, child) => {
        console.log('this is children in db', child);
        //console.log('this is the container in db', container);
        const updatedChildren = child.length == 0 ? [] : child;
        const {data, error} = await supabase
        .from('Container')
        .update({
            children: updatedChildren,
        })
        .eq('id', container.id)
        .select();

        if(data){
            console.log('this is the updated children', data)
        }

        if(error){
            console.log(error)
        }
    }

    const updateElementGroupedDb = async(props, from) => {
        //console.log('updating element grouped db', props, from);
        const {data, error} = await supabase
        .from(from)
        .update({
            grouped: props.grouped,
            grouped_by: props.grouped_by,
            xpos: props.xpos,
            ypos: props.ypos,
            in_screen: props.in_screen,
        })
        .eq('id', props.id);

        if(error){
            console.log(error)
        }
    }

    const saveElementGrouped = async(props) => {
        switch (props.type) {
            case 'textfield':
                updateElementGroupedDb(props, 'TextField');
                break;
            case 'button':
                updateElementGroupedDb(props, 'Button');
                break;
            case 'text':
                updateElementGroupedDb(props, 'Text');
                break;
            case 'image':
                updateElementGroupedDb(props, 'Image');
                break;
            case 'switch':
                updateElementGroupedDb(props, 'Switch');
                break;
            case 'container':
                updateElementGroupedDb(props, 'Container'); 
            default:
                break;
        }
    }

    const makeElementContainerChild = async(item, pos, container) => {
        // add element as a container child
        // set element grouped_by and grouped
        // update container db
        // update element db

        console.log('adding element to container in MakeElementContainerChild')

        //('Initial item being added:', item);
        //console.log('Current container children before add:', container.children);
    

        const containerX = pos.xpos - container.xpos;
        const containerY = pos.ypos - container.ypos;

         // Get current container to preserve child dimensions
        const currentContainer = element.find(el => el.id === container.id);
        //console.log('Current container state:', currentContainer);

        setElement( prevElements =>
            prevElements.map((el)=>{
                if(el.id == container.id){
                    return {
                        ...el,
                        children: [
                        ...el.children,  // Use original children directly
                          { ...item, xpos: containerX, ypos: containerY, grouped: true, grouped_by: container.id, in_screen: pos.in_screen},
                        ],
                    }
                }else if(el.id == item.id){
                    return {
                        ...el,
                        grouped_by: container.id,
                        grouped: true,
                    }
                }else{
                    return{...el}
                }
            })
        )

        // Update database
       // await updateContainerChildDb(container, children);
        await updateContainerChildDb(container, [
            ...currentContainer.children,
            { ...item, xpos: containerX, ypos: containerY, grouped: true, grouped_by: container.id, in_screen: pos.in_screen }
        ]);
    
        
        const props = {id: item.id, type: item.type, grouped: true, grouped_by: container.id, xpos: pos.xpos - container.xpos, ypos: pos.ypos - container.ypos, in_screen: pos.in_screen}
        await saveElementGrouped(props);

    }


    const removeElementContainerChild = async(item, pos) => {
        // Get container and filtered children
        const container = element.filter((i) => i.id === item.grouped_by)[0];
        const updatedChildren = container.children.filter((child) => child.id !== item.id);
    
        const containerX = container.xpos + pos.xpos;
        const containerY = container.ypos + pos.ypos;
    
        // Update DB first to ensure consistency
        try {
            await updateContainerChildDb(container, updatedChildren);
            
            const props = {
                id: item.id,
                type: item.type,
                grouped: false,
                grouped_by: null,
                xpos: containerX,
                ypos: containerY,
            };
            
            await saveElementGrouped(props);
    
            // Only update state after DB operations succeed
            setElement(
                element.map((el) => {
                    if(el.id === container.id){
                        return {
                            ...el,
                            children: updatedChildren
                        };
                    } else if(el.id === item.id){
                        return {
                            ...el,
                            grouped_by: null,
                            grouped: false,
                            xpos: containerX,
                            ypos: containerY,
                        }
                    }
                    return el;
                })
            );
        } catch (error) {
            console.error('Failed to remove child:', error);
            // Consider adding error handling/user feedback
        }
    }

    // const removeElementContainerChild = async(item, pos) => {
    //     // get container
    //     const container = element.filter((i) => i.id === item.grouped_by);
    //     // const children = element.filter((item) => item.id == container[0].id)
    //     // .map((i) => i.children.filter((child) => child.id !== item.id));
    //     //console.log('removing child from container', item, container[0]);

    //     // console.log('Original container children:', container[0].children);
    //     const updatedChildren = container[0].children.filter((child) => child.id !== item.id);

    //     const containerX = container[0].xpos + pos.xpos;
    //     const containerY = container[0].ypos + pos.ypos;
    //     console.log('Children in remove function', updatedChildren);

    //     await updateContainerChildDb(container[0], updatedChildren);

    //     setElement( prevElements =>
    //         prevElements.map((el) => {
    //             if(el.id === container[0].id){
    //                 return {
    //                     ...el,
    //                     children: updatedChildren
    //                 };
    //             }else if(el.id === item.id){
    //                 return{
    //                     ...el,
    //                     grouped_by: null,
    //                     grouped: false,
    //                     xpos: containerX,
    //                     ypos: containerY,
    //                 }
    //             }else{
    //                 return{...el}
    //             }
    //         })
    //     )

    //     //const children = element.children.filter((j) => j.id !== item.id);
        
    //     //await updateContainerChildDb(container[0], updatedChildren);

    //     const props = {
    //         id: item.id, 
    //         type: item.type, 
    //         grouped: false, 
    //         grouped_by: null, 
    //         xpos: containerX, 
    //         ypos: containerY
    //     }
    //     await saveElementGrouped(props);
    // }

    const setCurrentActiveElement = (element) => {
        //console.log('new element', element);
        //console.log('current element state', activeElement);
        setActiveElement(element)
    }

    const setCanvasIsSelected = (boo) => {
        setIsCanvasSelected(boo)
    }

    const setCurrentFileFn = (fileId) =>  {
        setCurrentFile(fileId);
    }

    const setCurrentFileDetails = (name, screensize) => {
        setCurrentFileName(name);
        setCurrentFileScreenSize(screensize);
    }

    const updateLatestContextMenu = (x, y) => {
        // update element control options
        setLatestContextMenu({x: x, y: y})
    }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// TEXT FIELD //////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

    const updateTextFieldDB = async(props) => {
        //console.log(props)
        const {data, error} = await supabase
        .from('TextField')
        .update({
            xpos: props.xpos,
            ypos: props.ypos,
            width: props.width,
            height: props.height,
            scaleX: props.scaleX,
            scaleY: props.scaleY,
            in_screen: props.in_screen
        })
        .eq('id', props.id);

        if(error){
            console.log(error)
            return;
        }
    }


    const updateTextFieldStylingDB = async(props) => {
        //console.log(props)
        const {data, error} = await supabase
        .from('TextField')
        .update({
            fill: props.fill,
            border_colour: props.border_colour,
            border_width: props.border_width,
            border_radius_tl: props.border_radius_tl,
            border_radius_tr: props.border_radius_tr,
            border_radius_bl: props.border_radius_bl,
            border_radius_br: props.border_radius_br,
        })
        .eq('id', props.id);

        if(error){
            console.log(error)
            return;
        }
    }

    const updateTextFieldZIndex = async(props) => {
        //console.log(props)
        const {data, error} = await supabase
        .from('TextField')
        .update({
            zIndex: props.zIndex,
        })
        .eq('id', props.id);

        if(error){
            console.log(error)
            return;
        }
    }


    const addTextFieldDB = async(obj) => {
        const {data, error}  = await supabase
        .from('TextField')
        .insert({
            user: authContext.user.id,
            width: obj.width,
            height: obj.height,
            new_width: obj.new_width,
            new_height: obj.new_height,
            xpos: obj.xpos,
            ypos: obj.ypos,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            prototyping: false,
            screen: obj.screen,
            file: obj.file,
            name: obj.name,
            zIndex: obj.zIndex
        })
        .select();

        if(data){
            return data;
        }

        if(error){
            console.log(error);
        }
    }

    const updateTextFieldProto = async(props) => {
        //update main element in db
        const {data, error} = await supabase
        .from('TextField')
        .update({
            prototyping: props.prototyping,
            proto_trigger: props.trigger,
            proto_interaction: props.interaction,
            proto_passdata: props.passdata,
            navigate_to: props.navigateTo
        })
        .eq('id', props.id);

        if(data){
           // console.log(data);
        }
  
        if(error){
            console.log(error)
        }

        //update element locally
        updateLocalProto(props);
        //update container

    }
    

    const loadTextFieldsDB = async(id) => {
        // load elements 
        const { data, error } = await supabase
        .from('TextField')
        .select()
        .eq('file', `${id}`)

        if(data){
            //console.log(data)
            //console.log('loading textfield', data)
            setElementLoader((currentElements)=>[...currentElements, ...data]);
        }

        if(error){
            console.log(error)
        }
    }

    const updateTextFieldPanelDb = async(props) => {
        const {data, error} = await supabase
        .from('TextField')
        .update({
            placeholder: props.placeholder,
            value: props.value,
            font: props.font,
            font_size: props.font_size,
            font_colour: props.font_colour,
        })
        .eq('id', props.id);

        if(error){
            console.log(error)
            return;
        }
    }

    const deleteTextField = async(props) => {
        const {data, error} = await supabase
        .from('TextField')
        .delete()
        .eq('id', props.id);

        if(error){
            console.log(error)
            return;
        }
    }

    const updateTextFieldChildDb = async(props) => {
        // if element is a child of a container
        // update child


        const { data:containerData, error:containerError } = await supabase
        .from('Container')
        .select('children')
        .eq('id', props.grouped_by)
        .single();

        if(containerError){
            console.log(containerError);
        }

        const updatedChildren = containerData.children.map((child) =>
            child.id === props.id
              ? { 
                  ...child, 
                  placeholder: props.placeholder ? props.placeholder : child.placeholder,
                  value: props.value ? props.value : child.value,
                  font: props.font ? props.font : child.font,
                  font_size: props.font_size ? props.font_size : child.font_size,
                  font_colour: props.font_colour ? props.font_colour : child.font_colour,
                }
              : child
          );
        

        // Update the 'Container' row with the modified 'children' array
        const { data, error } = await supabase
        .from('Container')
        .update({ children: updatedChildren })
        .eq('id', props.grouped_by);

        if(error){
            console.log(error);
        }
    }

    const updateTextFieldPanel = (props) => { 

        if(props.grouped){

            setElement(prevElements =>
                prevElements.map((el) => {
                  if (el?.id === props.grouped_by) {
                    const output = el.children.map((child) => {
                      if (child.id !== props.id) return child;
                      return {
                        ...child,
                        placeholder: props.placeholder ? props.placeholder : child.placeholder,
                        value: props.value ? props.value : child.value,
                        font: props.font ? props.font : child.font,
                        font_size: props.font_size ? props.font_size : child.font_size,
                        font_colour: props.font_colour ? props.font_colour : child.font_colour,
                      };
                    });
                    return {
                      ...el,
                      children: output,
                    };
                  }else if(el.id == props.id){
                    return{
                        ...el,
                        placeholder: props.placeholder ? props.placeholder : el.placeholder,
                        value: props.value ? props.value : el.value,
                        font: props.font ? props.font : el.font,
                        font_size: props.font_size ? props.font_size : el.font_size,
                        font_colour: props.font_colour ? props.font_colour : el.font_colour,
                    }
                  }else{
                    return { ...el };
                  }
                  
                })
            );

            updateTextFieldChildDb(props)
            updateTextFieldPanelDb(props)

        }else{
            //(props);
            updateTextFieldPanelDb(props)
            setElement(prevElements  =>
                prevElements.map((element) => {
                    return{
                        ...element,
                        placeholder: props.placeholder && element.id == props.id ? props.placeholder : element.placeholder,
                        value: props.value && element.id == props.id ? props.value : element.value,
                        font: props.font && element.id == props.id ? props.font : element.font,
                        font_size: props.font_size && element.id == props.id ? props.font_size : element.font_size,
                        font_colour: props.font_colour && element.id == props.id ? props.font_colour : element.font_colour,
                    }
                })
            ) 
        }
        
    }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// BUTTON //////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const updateButtonDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Button')
    .update({
        xpos: props.xpos,
        ypos: props.ypos,
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        in_screen: props.in_screen
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const updateButtonStylingDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Button')
    .update({
        fill: props.fill,
        border_colour: props.border_colour,
        border_width: props.border_width,
        border_radius_tl: props.border_radius_tl,
        border_radius_tr: props.border_radius_tr,
        border_radius_bl: props.border_radius_bl,
        border_radius_br: props.border_radius_br,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const deleteButton = async(props) => {
    //console.log('deleting button')
    const {data, error} = await supabase
    .from('Button')
    .delete()
    .eq('id', props.id);

    if(data){
        //('deleted button', data);
    }

    if(error){
        console.log(error)
        return;
    }
}

const addButtonDB = async(obj) => {
    const {data, error}  = await supabase
    .from('Button')
    .insert({
        user: authContext.user.id,
        width: obj.width,
        height: obj.height,
        new_width: obj.new_width,
        new_height: obj.new_height,
        xpos: obj.xpos,
        ypos: obj.ypos,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        prototyping: false,
        screen: obj.screen,
        file: obj.file,
        name: obj.name,
        zIndex: obj.zIndex,
    })
    .select();

    if(data){
        return data;
    }

    if(error){
        console.log(error);
    }
}

const updateButtonProto = async(props) => {
    const {data, error} = await supabase
    .from('Button')
    .update({
        prototyping: props.prototyping,
        proto_trigger: props.trigger,
        proto_interaction: props.interaction,
        proto_passdata: props.passdata,
        navigate_to: props.navigateTo
    })
    .eq('id', props.id);

    if(data){
       // console.log(data);
    }

    if(error){
        console.log(error)
    }

    updateLocalProto(props);

}

const loadButtonDB = async(id) => {
    // load elements 
    const { data, error } = await supabase
    .from('Button')
    .select()
    .eq('file', `${id}`)

    if(data){
        //console.log('loading buttons', data)
        setElementLoader((currentElements)=>[...currentElements, ...data]);
    }

    if(error){
        console.log(error)
    }
}

const updateButtonPanelDb = async(props) => {
    const {data, error} = await supabase
    .from('Button')
    .update({
        value: props.value,
        font: props.font,
        font_size: props.font_size,
        font_colour: props.font_colour,
        hover_fill: props.hover_fill,
        hover_border_colour: props.hover_border_colour,
        hover_border_width: props.hover_border_width
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const updateButtonZIndex = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Button')
    .update({
        zIndex: props.zIndex,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateButtonChildDb = async(props) => {
    // if element is a child of a container
    // update child


    const { data:containerData, error:containerError } = await supabase
    .from('Container')
    .select('children')
    .eq('id', props.grouped_by)
    .single();

    if(containerError){
        console.log(containerError);
    }

    const updatedChildren = containerData.children.map((child) =>
        child.id === props.id
          ? { 
              ...child, 
              value: props.value ? props.value : child.value,
              font: props.font ? props.font : child.font,
              font_size: props.font_size ? props.font_size : child.font_size,
              font_colour: props.font_colour ? props.font_colour : child.font_colour,
              hover_fill: props.hover_fill ? props.hover_fill : child.hover_fill,
              hover_border_colour: props.hover_border_colour ? props.hover_border_colour : child.hover_border_colour,
              hover_border_width: props.hover_border_width ? props.hover_border_width : child.hover_border_width,
            }
          : child
      );
    

    // Update the 'Container' row with the modified 'children' array
    const { data, error } = await supabase
    .from('Container')
    .update({ children: updatedChildren })
    .eq('id', props.grouped_by);

    if(error){
        console.log(error);
    }
}

const updateButtonPanel = (props) => { 

    if(props.grouped){

        setElement(prevElements =>
            prevElements.map((el) => {
              if (el?.id === props.grouped_by) {
                const output = el.children.map((child) => {
                  if (child.id !== props.id) return child;
                  return {
                    ...child,
                    value: props.value ? props.value : child.value,
                    font: props.font ? props.font : child.font,
                    font_size: props.font_size ? props.font_size : child.font_size,
                    font_colour: props.font_colour ? props.font_colour : child.font_colour,
                    hover_fill: props.hover_fill ? props.hover_fill : child.hover_fill,
                    hover_border_colour: props.hover_border_colour ? props.hover_border_colour : child.hover_border_colour,
                    hover_border_width: props.hover_border_width ? props.hover_border_width : child.hover_border_width,
                  };
                });
                return {
                  ...el,
                  children: output,
                };
              }else if(el.id == props.id){
                return{
                    ...el,
                    value: props.value ? props.value : el.value,
                    font: props.font ? props.font : el.font,
                    font_size: props.font_size ? props.font_size : el.font_size,
                    font_colour: props.font_colour ? props.font_colour : el.font_colour,
                    hover_fill: props.hover_fill ? props.hover_fill : el.hover_fill,
                    hover_border_colour: props.hover_border_colour ? props.hover_border_colour : el.hover_border_colour,
                    hover_border_width: props.hover_border_width ? props.hover_border_width : el.hover_border_width,
                }
              }else{
                return { ...el };
              }
              
            })
        );

        updateButtonChildDb(props)
        updateButtonPanelDb(props)

    }else{
        //console.log(props);
        updateButtonPanelDb(props)
        setElement(prevElements =>
            prevElements.map((element) => {
                return{
                    ...element,
                    value: props.value && element.id == props.id ? props.value : element.value,
                    font: props.font && element.id == props.id ? props.font : element.font,
                    font_size: props.font_size && element.id == props.id ? props.font_size : element.font_size,
                    font_colour: props.font_colour && element.id == props.id ? props.font_colour : element.font_colour,
                    hover_fill: props.hover_fill && element.id == props.id ? props.hover_fill : element.hover_fill,
                    hover_border_colour: props.hover_border_colour && element.id == props.id ? props.hover_border_colour : element.hover_border_colour,
                    hover_border_width: props.hover_border_width && element.id == props.id ? props.hover_border_width : element.hover_border_width,
                }
            })
        ) 
    }
    
}


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// Text //////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const updateTextDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Text')
    .update({
        xpos: props.xpos,
        ypos: props.ypos,
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        in_screen: props.in_screen
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const addTextDB = async(obj) => {
    const {data, error}  = await supabase
    .from('Text')
    .insert({
        user: authContext.user.id,
        width: obj.width,
        height: obj.height,
        new_width: obj.new_width,
        new_height: obj.new_height,
        xpos: obj.xpos,
        ypos: obj.ypos,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        prototyping: false,
        screen: obj.screen,
        file: obj.file,
        name: obj.name,
        zIndex: obj.zIndex
    })
    .select();

    if(data){
        return data;
    }

    if(error){
        console.log(error);
    }
}

const deleteText = async(props) => {
    const {data, error} = await supabase
    .from('Text')
    .delete()
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateTextProto = async(props) => {
    const {data, error} = await supabase
    .from('Text')
    .update({
        prototyping: props.prototyping,
        proto_trigger: props.trigger,
        proto_interaction: props.interaction,
        proto_passdata: props.passdata,
        navigate_to: props.navigateTo
    })
    .eq('id', props.id);

    if(data){
       // console.log(data);
    }

    if(error){
        console.log(error)
    }

    updateLocalProto(props);
  
}

const updateTextZIndex = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Text')
    .update({
        zIndex: props.zIndex,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const loadTextDB = async(id) => {
    // load elements 
    const { data, error } = await supabase
    .from('Text')
    .select()
    .eq('file', `${id}`)

    if(data){
        //console.log('loading text', data)
        setElementLoader((currentElements)=>[...currentElements, ...data]);
    }

    if(error){
        console.log(error)
    }
}

const updateTextPanelDb = async(props) => {
    const {data, error} = await supabase
    .from('Text')
    .update({
        value: props.value,
        font: props.font,
        font_weight: props.font_weight,
        font_size: props.font_size,
        font_colour: props.font_colour,
        line_height: props.line_height,
        letter_spacing: props.letter_spacing,
        text_alignment: props.text_alignment,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateTextChildDb = async(props) => {
    // if element is a child of a container
    // update child
    const { data:containerData, error:containerError } = await supabase
    .from('Container')
    .select('children')
    .eq('id', props.grouped_by)
    .single();

    if(containerError){
        console.log(containerError);
    }

    const updatedChildren = containerData.children.map((child) =>
        child.id === props.id
          ? { 
              ...child, 
              value: props.value ? props.value : child.value,
              font: props.font ? props.font : child.font,
              font_weight: props.font_weight ? props.font_weight : child.font_weight,
              font_size: props.font_size ? props.font_size : child.font_size,
              font_colour: props.font_colour ? props.font_colour : child.font_colour,
              line_height: props.line_height ? props.line_height : child.line_height,
              letter_spacing: props.letter_spacing ? props.letter_spacing : child.letter_spacing,
              text_alignment: props.text_alignment ? props.text_alignment : child.text_alignment
            }
          : child
      );
    

    // Update the 'Container' row with the modified 'children' array
    const { data, error } = await supabase
    .from('Container')
    .update({ children: updatedChildren })
    .eq('id', props.grouped_by);

    if(error){
        console.log(error);
    }
}

const updateTextPanel = (props) => { 

    if(props.grouped){

        setElement(prevElements =>
            prevElements.map((el) => {
              if (el?.id === props.grouped_by) {
                const output = el.children.map((child) => {
                  if (child.id !== props.id) return child;
                  return {
                    ...child,
                    value: props.value ? props.value : child.value,
                    font: props.font ? props.font : child.font,
                    font_weight: props.font_weight ? props.font_weight : child.font_weight,
                    font_size: props.font_size ? props.font_size : child.font_size,
                    font_colour: props.font_colour ? props.font_colour : child.font_colour,
                    line_height: props.line_height ? props.line_height : child.line_height,
                    letter_spacing: props.letter_spacing ? props.letter_spacing : child.letter_spacing,
                    text_alignment: props.text_alignment ? props.text_alignment : child.text_alignment
                  };
                });
                return {
                  ...el,
                  children: output,
                };
              }else if(el.id == props.id){
                return{
                    ...el,
                    value: props.value ? props.value : el.value,
                    font: props.font ? props.font : el.font,
                    font_weight: props.font_weight ? props.font_weight : el.font_weight,
                    font_size: props.font_size ? props.font_size : el.font_size,
                    font_colour: props.font_colour ? props.font_colour : el.font_colour,
                    line_height: props.line_height ? props.line_height : el.line_height,
                    letter_spacing: props.letter_spacing ? props.letter_spacing : el.letter_spacing,
                    text_alignment: props.text_alignment ? props.text_alignment : el.text_alignment
                }
              }else{
                return { ...el };
              }
              
            })
        );

        updateTextChildDb(props);
        updateTextPanelDb(props);
    }else{
        //console.log(props);
        updateTextPanelDb(props)
        setElement(prevElements =>
            prevElements.map((element) => {
                return{
                    ...element,
                    value: props.value && element.id == props.id ? props.value : element.value,
                    font: props.font && element.id == props.id ? props.font : element.font,
                    font_weight: props.font_weight && element.id == props.id ? props.font_weight : element.font_weight,
                    font_size: props.font_size && element.id == props.id ? props.font_size : element.font_size,
                    font_colour: props.font_colour && element.id == props.id ? props.font_colour : element.font_colour,
                    line_height: props.line_height && element.id == props.id ? props.line_height : element.line_height,
                    letter_spacing: props.letter_spacing && element.id == props.id ? props.letter_spacing : element.letter_spacing,
                    text_alignment: props.text_alignment && element.id == props.id ? props.text_alignment : element.text_alignment
                }
            })
        ) 
    }
}


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// IMAGE //////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const updateImageDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Image')
    .update({
        xpos: props.xpos,
        ypos: props.ypos,
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        in_screen: props.in_screen
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const updateImageStylingDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Image')
    .update({
        border_radius_tl: props.border_radius_tl,
        border_radius_tr: props.border_radius_tr,
        border_radius_bl: props.border_radius_bl,
        border_radius_br: props.border_radius_br,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const addImageDB = async(obj) => {
    const {data, error}  = await supabase
    .from('Image')
    .insert({
        user: authContext.user.id,
        width: obj.width,
        height: obj.height,
        new_width: obj.new_width,
        new_height: obj.new_height,
        xpos: obj.xpos,
        ypos: obj.ypos,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        prototyping: false,
        screen: obj.screen,
        file: obj.file,
        name: obj.name,
        zIndex: obj.zIndex
    })
    .select();

    if(data){
        return data;
    }

    if(error){
        console.log(error);
    }
}

const deleteImage = async(props) => {
    const {data, error} = await supabase
    .from('Image')
    .delete()
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateImageProto = async(props) => {
    const {data, error} = await supabase
    .from('Image')
    .update({
        prototyping: props.prototyping,
        proto_trigger: props.trigger,
        proto_interaction: props.interaction,
        proto_passdata: props.passdata,
        navigate_to: props.navigateTo
    })
    .eq('id', props.id);

    if(data){
       // console.log(data);
    }

    if(error){
        console.log(error)
    }

    updateLocalProto(props);
  
}

const updateImageZIndex = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Image')
    .update({
        zIndex: props.zIndex,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const loadImageDB = async(id) => {
    // load elements 
    const { data, error } = await supabase
    .from('Image')
    .select()
    .eq('file', `${id}`)

    if(data){
        //console.log('loading images', data)
        setElementLoader((currentElements)=>[...currentElements, ...data]);
    }

    if(error){
        console.log(error)
    }
}

const updateImagePanelDb = async(props) => {
    const {data, error} = await supabase
    .from('Image')
    .update({
        url: props.url,
        image_name: props.image_name,
        aspect_ratio: props.aspect_ratio
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateImageChildDb = async(props) => {
    // if element is a child of a container
    // update child
    const { data:containerData, error:containerError } = await supabase
    .from('Container')
    .select('children')
    .eq('id', props.grouped_by)
    .single();

    if(containerError){
        console.log(containerError);
    }

    const updatedChildren = containerData.children.map((child) =>
        child.id === props.id
          ? { 
              ...child, 
              url: props.url ? props.url : child.url,
              image_name: props.image_name ? props.image_name : child.image_name,
              aspect_ratio: props.aspect_ratio ? props.aspect_ratio : child.aspect_ratio,
            }
          : child
      );
    

    // Update the 'Container' row with the modified 'children' array
    const { data, error } = await supabase
    .from('Container')
    .update({ children: updatedChildren })
    .eq('id', props.grouped_by);

    if(error){
        console.log(error);
    }
}

const updateImagePanel = (props) => {
    
    if(props.grouped){

        setElement(prevElements =>
            prevElements.map((el) => {
              if (el?.id === props.grouped_by) {
                const output = el.children.map((child) => {
                  if (child.id !== props.id) return child;
                  return {
                    ...child,
                    url: props.url ? props.url : child.url,
                    image_name: props.image_name ? props.image_name : child.image_name,
                    aspect_ratio: props.aspect_ratio ? props.aspect_ratio : child.aspect_ratio,
                  };
                });
                return {
                  ...el,
                  children: output,
                };
              }else if(el.id == props.id){
                return{
                    ...el,
                    url: props.url ? props.url : el.url,
                    image_name: props.image_name ? props.image_name : el.image_name,
                    aspect_ratio: props.aspect_ratio ? props.aspect_ratio : el.aspect_ratio,
                }
              }else{
                return { ...el };
              }
              
            })
        );

        updateImageChildDb(props);
        updateImagePanelDb(props);
    }else{
        //console.log(props);
        updateImagePanelDb(props)
        setElement(prevElements =>
            prevElements.map((element) => {
                return{
                    ...element,
                    url: props.url && element.id == props.id ? props.url : element.url,
                    image_name: props.image_name && element.id == props.id ? props.image_name : element.image_name,
                    aspect_ratio: props.aspect_ratio && element.id == props.id ? props.aspect_ratio : element.aspect_ratio,
                }
            })
        ) 
    }

}


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// Switch //////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const updateSwitchDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Switch')
    .update({
        xpos: props.xpos,
        ypos: props.ypos,
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        in_screen: props.in_screen
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const updateSwitchStylingDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Switch')
    .update({
        border: props.border,
        border_width: props.border_width,
        border_radius_tl: props.border_radius_tl,
        border_radius_tr: props.border_radius_tr,
        border_radius_bl: props.border_radius_bl,
        border_radius_br: props.border_radius_br,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const addSwitchDB = async(obj) => {
    const {data, error}  = await supabase
    .from('Switch')
    .insert({
        user: authContext.user.id,
        width: obj.width,
        height: obj.height,
        new_width: obj.new_width,
        new_height: obj.new_height,
        xpos: obj.xpos,
        ypos: obj.ypos,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        prototyping: false,
        screen: obj.screen,
        file: obj.file,
        name: obj.name,
        zIndex: obj.zIndex
    })
    .select();

    if(data){
        return data;
    }

    if(error){
        console.log(error);
    }
}

const deleteSwitch = async(props) => {
    const {data, error} = await supabase
    .from('Switch')
    .delete()
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateSwitchProto = async(props) => {
    const {data, error} = await supabase
    .from('Switch')
    .update({
        prototyping: props.prototyping,
        proto_trigger: props.trigger,
        proto_interaction: props.interaction,
        proto_passdata: props.passdata,
        navigate_to: props.navigateTo
    })
    .eq('id', props.id);

    if(data){
       // console.log(data);
    }

    if(error){
        console.log(error)
    }

    updateLocalProto(props);
   
}

const updateSwitchZIndex = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Switch')
    .update({
        zIndex: props.zIndex,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const loadSwitchDB = async(id) => {
    // load elements 
    const { data, error } = await supabase
    .from('Switch')
    .select()
    .eq('file', `${id}`)

    if(data){
        //console.log('loading switch', data)
        setElementLoader((currentElements)=>[...currentElements, ...data]);
    }

    if(error){
        console.log(error)
    }
}

const updateSwitchPanelDb = async(props) => {
    const {data, error} = await supabase
    .from('Switch')
    .update({
        active_colour: props.active_colour,
        disabled_colour: props.disabled_colour,
        notch_colour: props.notch_colour,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const updateSwitchChildDb = async(props) => {
    // if element is a child of a container
    // update child
    const { data:containerData, error:containerError } = await supabase
    .from('Container')
    .select('children')
    .eq('id', props.grouped_by)
    .single();

    if(containerError){
        console.log(containerError);
    }

    const updatedChildren = containerData.children.map((child) =>
        child.id === props.id
          ? { 
              ...child, 
              active_colour: props.active_colour ? props.active_colour : child.active_colour,
              disabled_colour: props.disabled_colour ? props.disabled_colour : child.disabled_colour,
              notch_colour: props.notch_colour ? props.notch_colour : child.notch_colour,
            }
          : child
      );
    

    // Update the 'Container' row with the modified 'children' array
    const { data, error } = await supabase
    .from('Container')
    .update({ children: updatedChildren })
    .eq('id', props.grouped_by);

    if(error){
        console.log(error);
    }
}

const updateSwitchPanel = (props) => { 
    console.log('switch update props', props);
    if(props.grouped){

        setElement(prevElements =>
            prevElements.map((el) => {
              if (el?.id === props.grouped_by) {
                const output = el.children.map((child) => {
                  if (child.id !== props.id) return child;
                  return {
                    ...child,
                    active_colour: props.active_colour ? props.active_colour : child.active_colour,
                    disabled_colour: props.disabled_colour ? props.disabled_colour : child.disabled_colour,
                    notch_colour: props.notch_colour ? props.notch_colour : child.notch_colour,
                  };
                });
                return {
                  ...el,
                  children: output,
                };
              }else if(el.id == props.id){
                return{
                    ...el,
                    active_colour: props.active_colour ? props.active_colour : el.active_colour,
                    disabled_colour: props.disabled_colour ? props.disabled_colour : el.disabled_colour,
                    notch_colour: props.notch_colour ? props.notch_colour : el.notch_colour,
                }
              }else{
                return { ...el };
              }
              
            })
        );

        updateSwitchChildDb(props);
        updateSwitchPanelDb(props);
    }else{
        updateSwitchPanelDb(props)
        setElement(prevElements =>
            prevElements.map((element) => {
                return{
                    ...element,
                    active_colour: props.type == 'switch' && props.active_colour && element.id == props.id ? props.active_colour : element.active_colour,
                    disabled_colour: props.type == 'switch' && props.disabled_colour && element.id == props.id ? props.disabled_colour : element.disabled_colour,
                    notch_colour: props.type == 'switch' && props.notch_colour && element.id == props.id ? props.notch_colour : element.notch_colour,
                }
            })
        ) 
    }

}


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// Container ////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const updateContainerDB = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Container')
    .update({
        xpos: props.xpos,
        ypos: props.ypos,
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        in_screen: props.in_screen
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}


const updateContainerStylingDB = async(props) => {
    //console.log('updating container style', props)
    const {data, error} = await supabase
    .from('Container')
    .update({
        fill: props.fill,
        border: props.border,
        border_width: props.border_width,
        border_radius_tl: props.border_radius_tl,
        border_radius_tr: props.border_radius_tr,
        border_radius_bl: props.border_radius_bl,
        border_radius_br: props.border_radius_br,
    })
    .eq('id', props.id);

    if(data){
        //console.log('container style data', data)
    }

    if(error){
        console.log(error)
        return;
    }
}


const addContainerDB = async(obj) => {
    const {data, error}  = await supabase
    .from('Container')
    .insert({
        user: authContext.user.id,
        width: obj.width,
        height: obj.height,
        xpos: obj.xpos,
        ypos: obj.ypos,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        prototyping: false,
        screen: obj.screen,
        file: obj.file,
        name: obj.name,
        zIndex: obj.zIndex
    })
    .select();

    if(data){
        return data;
    }

    if(error){
        console.log(error);
    }
}

const deleteContainer = async(props) => {
    const {data, error} = await supabase
    .from('Container')
    .delete()
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateContainerProto = async(props) => {
    const {data, error} = await supabase
    .from('Container')
    .update({
        prototyping: props.prototyping,
        proto_trigger: props.trigger,
        proto_interaction: props.interaction,
        proto_passdata: props.passdata,
        navigate_to: props.navigateTo
    })
    .eq('id', props.id);

    if(data){
       // console.log(data);
    }

    if(error){
        console.log(error)
    }

    updateLocalProto(props); 
}

const updateContainerZIndex = async(props) => {
    //console.log(props)
    const {data, error} = await supabase
    .from('Container')
    .update({
        zIndex: props.zIndex,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const loadContainerDB = async(id) => {
    // load elements 
    const { data, error } = await supabase
    .from('Container')
    .select()
    .eq('file', `${id}`)

    if(data){
        //console.log('loading switch', data)
        setElementLoader((currentElements)=>[...currentElements, ...data]);
    }

    if(error){
        console.log(error)
    }
}

const updateContainerPanelDb = async(props) => {
    const {data, error} = await supabase
    .from('Container')
    .update({
        active_colour: props.active_colour,
        disabled_colour: props.disabled_colour,
        notch_colour: props.notch_colour,
    })
    .eq('id', props.id);

    if(error){
        console.log(error)
        return;
    }
}

const updateContainerPanel = (props) => { 
    updateContainerPanelDb(props)
    setElement(prevElements =>
        prevElements.map((element) => {
            return{
                ...element,
                active_colour: props.type == 'switch' && props.active_colour && element.id == props.id ? props.active_colour : element.active_colour,
                disabled_colour: props.type == 'switch' && props.disabled_colour && element.id == props.id ? props.disabled_colour : element.disabled_colour,
                notch_colour: props.type == 'switch' && props.notch_colour && element.id == props.id ? props.notch_colour : element.notch_colour,
            }
        })
    ) 
}

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// Add Elements ////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////


    const loadElements = async(id) => {
        if(loaded == false){
            await loadTextFieldsDB(id);
            await loadButtonDB(id);
            await loadTextDB(id);
            await loadImageDB(id);
            await loadSwitchDB(id);    
            await loadContainerDB(id);
            //console.log('finished loading elements');
            setLoaded(true);      
        }
       
    }

    const sortElements = async() => {
        // sort elements to correct order
        // loop through elements
        //console.log('array before sorting', element);
        const sorted = elementLoader.sort((a, b) => a.zIndex - b.zIndex);
        //console.log('sorted array', sorted);

        //set elements for rendering
        setElement(sorted);

        //clear elementloader for performance
        setElementLoader([]);
    }

    const updatedElementDb = (props) =>{
            switch (props.type) {
                case 'textfield':
                    updateTextFieldDB(props);
                    break;
                case 'button':
                    updateButtonDB(props);
                    break;
                case 'text':
                    updateTextDB(props);
                    break;
                case 'image':
                    updateImageDB(props);
                    break;
                case 'switch':
                    updateSwitchDB(props);
                    break;
                case 'container':
                    updateContainerDB(props);
                    break;
                default:
                    break;
            }
    }

    const updateChildElementDb = async(props) => {
        // if element is a child of a container
        // update child
        const { data:containerData, error:containerError } = await supabase
        .from('Container')
        .select('children')
        .eq('id', props.grouped_by)
        .single();
    
        if(containerError){
            console.log(containerError);
        }
    
        const updatedChildren = containerData.children.map((child) =>
            child.id === props.id
              ? { 
                  ...child, 
                    xpos: props && props.xpos ? props.xpos : child.xpos,
                    ypos: props && props.ypos ? props.ypos : child.ypos,
                    width: props && props.width ? props.width : child.width,
                    height: props && props.height ? props.height : child.height,
                    scaleX: props && props.scaleX ? props.scaleX : child.scaleX,
                    scaleY: props && props.scaleY ? props.scaleY : child.scaleY,
                    in_screen: props && props.in_screen ? props.in_screen : child.in_screen,
                }
              : child
          );
        
    
        // Update the 'Container' row with the modified 'children' array
        const { data, error } = await supabase
        .from('Container')
        .update({ children: updatedChildren })
        .eq('id', props.grouped_by)
        .select();

        if(data){
            console.log('updated container childred', data);
        }
    
        if(error){
            console.log(error);
        }
    }

    const updateElement = async(props) => {
        //console.log('update text field in context', props)
        if(props.grouped){

            setElement(prevElements =>
                prevElements.map((el) => {
                  if (el?.id === props.grouped_by) {
                    const output = el.children.map((child) => {
                      if (child.id !== props.id) return child;
                      return {
                        ...child,
                        xpos: props && props.xpos ? props.xpos : child.xpos,
                        ypos: props && props.ypos ? props.ypos : child.ypos,
                        width: props && props.width ? props.width : child.width,
                        height: props && props.height ? props.height : child.height,
                        scaleX: props && props.scaleX ? props.scaleX : child.scaleX,
                        scaleY: props && props.scaleY ? props.scaleY : child.scaleY,
                        in_screen: props && props.in_screen ? props.in_screen : child.in_screen,
                      };
                    });
                    return {
                      ...el,
                      children: output,
                    };
                  }else if(el.id == props.id){
                    return{
                        ...el,
                        xpos: props && props.xpos ? props.xpos : el.xpos,
                        ypos: props && props.ypos ? props.ypos : el.ypos,
                        width: props && props.width ? props.width : el.width,
                        height: props && props.height ? props.height : el.height,
                        scaleX: props && props.scaleX ? props.scaleX : el.scaleX,
                        scaleY: props && props.scaleY ? props.scaleY : el.scaleY,
                        in_screen: props && props.in_screen ? props.in_screen : el.in_screen,
                    }
                  }else{
                    return { ...el };
                  }
                  
                })
            );

            updateChildElementDb(props);
            updatedElementDb(props);

        }else{
            updatedElementDb(props);
            setElement(prevElements =>
                prevElements.map((element) => {
                    return{
                        ...element,
                        xpos: props && props.xpos && element.id == props.id ? props.xpos : element.xpos,
                        ypos: props && props.ypos && element.id == props.id ? props.ypos : element.ypos,
                        width: props && props.width && element.id == props.id ? props.width : element.width,
                        height: props && props.height && element.id == props.id ? props.height : element.height,
                        scaleX: props && props.scaleX && element.id == props.id ? props.scaleX : element.scaleX,
                        scaleY: props && props.scaleY && element.id == props.id ? props.scaleY : element.scaleY,
                        in_screen: props && props.in_screen && element.id == props.id ? props.in_screen : element.in_screen,
                    }
                })
            ) 
        }
        
    }

    const updatedElementStylingDb = (props) =>{
        //console.log(props)
        switch (props.type) {
            case 'textfield':
                updateTextFieldStylingDB(props);
                break;
            case 'button':
                updateButtonStylingDB(props);
                break;
            case 'image':
                updateImageStylingDB(props);
                break;
            case 'switch':
                updateSwitchStylingDB(props);
                break;
            case 'container': 
                updateContainerStylingDB(props);
                break;
            default:
                break;
        }
    }

    const updateChildElementStylingDb = async(props) => {

        // fill: props.fill,
        // border_colour: props.border_colour,
        // border_width:props.border_width,
        // border_radius_tl: props.border_radius_tl,
        // border_radius_tr: props.border_radius_tr,
        // border_radius_bl: props.border_radius_bl,
        // border_radius_br: props.border_radius_br,

        const { data:containerData, error:containerError } = await supabase
        .from('Container')
        .select('children')
        .eq('id', props.grouped_by)
        .single();

        if(containerError){
            console.log(containerError);
        }

        const updatedChildren = containerData.children.map((child) =>
            child.id === props.id
              ? { 
                  ...child, 
                  fill: props && props.fill ? props.fill : child.fill,
                  border_colour: props && props.border_colour ? props.border_colour : child.border_colour,
                  border_width: props && props.border_width ? props.border_width : child.border_width,
                  border_radius_tl: props && props.border_radius_tl ? props.border_radius_tl : child.border_radius_tl,
                  border_radius_tr: props && props.border_radius_tr ? props.border_radius_tr : child.border_radius_tr,
                  border_radius_bl: props && props.border_radius_bl ? props.border_radius_bl : child.border_radius_bl,
                  border_radius_br: props && props.border_radius_br ? props.border_radius_br : child.border_radius_br,
                }
              : child
          );
        

        // Update the 'Container' row with the modified 'children' array
        const { data, error } = await supabase
        .from('Container')
        .update({ children: updatedChildren })
        .eq('id', props.grouped_by);

        if(error){
            console.log(error);
        }


    }

    const updateElementStyling = (props) => {
        //console.log('update text field in context', props);

        if(props.grouped == true){

            /// update child and ungrouped version
            setElement(prevElements =>
                prevElements.map((el) => {
                  if (el?.id === props.grouped_by) {
                    //console.log("element to change", el);
                    const output = el.children.map((child) => {
                      if (child.id !== props.id) return child;
                      return {
                        ...child,
                        fill: props && props.fill ? props.fill : child.fill,
                        border_colour: props && props.border_colour ? props.border_colour : child.border_colour,
                        border_width:  props && props.border_width !== undefined ? props.border_width : child.border_width,
                        border_radius_tl: props && props.border_radius_tl ? props.border_radius_tl : child.border_radius_tl,
                        border_radius_tr: props && props.border_radius_tr ? props.border_radius_tr : child.border_radius_tr,
                        border_radius_bl: props && props.border_radius_bl ? props.border_radius_bl : child.border_radius_bl,
                        border_radius_br: props && props.border_radius_br ? props.border_radius_br : child.border_radius_br,
                      };
                    });
                    return {
                      ...el,
                      children: output,
                    };
                  }else if(el.id == props.id){
                    return{
                        ...el,
                        fill: props && props.fill ? props.fill : el.fill,
                        border_colour: props && props.border_colour ? props.border_colour : el.border_colour,
                        border_width: props && props.border_width !== undefined ? props.border_width : el.border_width,
                        border_radius_tl: props && props.border_radius_tl ? props.border_radius_tl : el.border_radius_tl,
                        border_radius_tr: props && props.border_radius_tr ? props.border_radius_tr : el.border_radius_tr,
                        border_radius_bl: props && props.border_radius_bl ? props.border_radius_bl : el.border_radius_bl,
                        border_radius_br: props && props.border_radius_br ? props.border_radius_br : el.border_radius_br,
                    }
                  }else{
                    return { ...el };
                  }
                  
                })
            );

            //update props
            updateChildElementStylingDb(props);
            updatedElementStylingDb(props);

        }else{
            updatedElementStylingDb(props);
            setElement(prevElements =>
                prevElements.map((element) => {
                    return{
                        ...element,
                        fill: props && props.fill && element.id == props.id ? props.fill : element.fill,
                        border_colour: props && props.border_colour && element.id == props.id ? props.border_colour : element.border_colour,
                        border_width:  props && props.border_width !== undefined && element.id == props.id ? props.border_width : element.border_width,
                        border_radius_tl: props && props.border_radius_tl && element.id == props.id ? props.border_radius_tl : element.border_radius_tl,
                        border_radius_tr: props && props.border_radius_tr && element.id == props.id ? props.border_radius_tr : element.border_radius_tr,
                        border_radius_bl: props && props.border_radius_bl && element.id == props.id ? props.border_radius_bl : element.border_radius_bl,
                        border_radius_br: props && props.border_radius_br && element.id == props.id ? props.border_radius_br : element.border_radius_br,
                    }
                })
            ) 
        }
        
    }

    const updateElementCustomPanel = (props) => {
        //console.log('this is props', props)
        switch (props.type) {
            case 'textfield':
                updateTextFieldPanel(props)
                break;
            case 'button':
                updateButtonPanel(props)
                break;
            case 'text':
                updateTextPanel(props)
                break;
            case 'image':
                updateImagePanel(props)
                break;
            case 'switch':
                updateSwitchPanel(props)
                break;
            case 'container':
                updateContainerPanel(props);
                break;
            default:
                break;
        }
    }

    const addElement = (ele) => {
        const shiftExistingElements = async () => {
            // Increment zIndex of all existing elements by 1
            const updatedElements = element.map(el => {
                const props = {
                    id: el.id,
                    type: el.type,
                    zIndex: el.zIndex + 1  // Increment by 1
                };
                updateElementZindex(props); // Update in DB
                return {
                    ...el,
                    zIndex: el.zIndex + 1
                };
            });
            return updatedElements;
        };
        
        switch (ele) {
            case 'textfield':
                shiftExistingElements().then((updatedElements) => {
                    const tfObj = textField(screens, currentFile, element);
                    addTextFieldDB(tfObj).then((res) => {
                        tfObj.id = res[0].id;
                        // Add new element with zIndex 0
                        setElement([tfObj, ...updatedElements]);
                    });
                });
                break;
            case 'button':
                shiftExistingElements().then((updatedElements) => {
                    const buttonObj = button(screens, currentFile, element);
                    addButtonDB(buttonObj).then((res) => {
                        buttonObj.id = res[0].id;
                        setElement([buttonObj, ...updatedElements]);
                    });
                });
                break;
            case 'text':
                shiftExistingElements().then((updatedElements) => {
                    const textObj = text(screens, currentFile, element);
                    addTextDB(textObj).then((res) => {
                        textObj.id = res[0].id;
                        setElement([textObj, ...updatedElements]);
                    });
                });
                break;
            case 'image':
                return new Promise(async (resolve) => {
                    shiftExistingElements().then((updatedElements) => {
                        const imgObj = image(screens, currentFile, element);
                        addImageDB(imgObj).then((res) => {
                            imgObj.id = res[0].id;
                            setElement([imgObj, ...updatedElements]);
                            resolve(imgObj);
                        });
                    });
                });
                break;
            case 'switch':
                shiftExistingElements().then((updatedElements) => {
                    const switchObj = switcher(screens, currentFile, element);
                    addSwitchDB(switchObj).then((res) => {
                        switchObj.id = res[0].id;
                        setElement([switchObj, ...updatedElements]);
                    });
                });
                break;
            case 'container':
                shiftExistingElements().then((updatedElements) => {
                    const containerObj = container(screens, currentFile, element);
                    addContainerDB(containerObj).then((res) => {
                        containerObj.id = res[0].id;
                        setElement([containerObj, ...updatedElements]);
                    });
                });
                break;
                
            default:
                break;
        }
    }

    const updateElementZindex = (props) => {
        //console.log(props);
        switch (props.type) {
            case 'textfield':
                updateTextFieldZIndex(props);
                break;
            case 'button':
                updateButtonZIndex(props);
                break;
            case 'text':
                updateTextZIndex(props);
                break;
            case 'image':
                updateImageZIndex(props);
                break;    
            case 'switch':
                updateSwitchZIndex(props);
                break;
            case 'container':
                updateContainerZIndex(props);
                break;
            default:
                break;
        }
    }

    const updateChildZindexDb = async(newChildren, containerId) => {
        const {data, error} = await supabase
        .from('Container')
        .update({
            children: newChildren
        })
        .eq('id', containerId);
    
        if(error){
            console.log(error)
            return;
        }
    }

    const updateElementProto = (props) => {
        switch (props.type) {
            case 'textfield':
                //console.log('updating text field proto')
                updateTextFieldProto(props);
                break;
            case 'button':
                updateButtonProto(props);
                break;
            case 'text':
                updateTextProto(props);
                break;
            case 'image':
                updateImageProto(props);
                break;
            case 'switch':
                updateSwitchProto(props);
                break;  
            case 'container':
                updateContainerProto(props);
                break;  
            default:
                break;
        }
    }

    const setElementPrototyping = (e, boo) => {
        const id = e.id;
                //update root element
                setElement(prevElements =>
                    prevElements.map((element) => {
                        return{
                            ...element,
                            prototyping: element.id == id ? boo : element.prototyping
                        }
                    })
                )
                //update active element so change appears immediately
                const ElementToBeUpdated = element.filter((x) => x.id == id);
                const updatedElement = ElementToBeUpdated.map((element) => {
                    return{
                        ...element,
                        prototyping: element.id == id ? boo : element.prototyping
                    }
                })
                setActiveElement(updatedElement[0]);    
    }

    const setElementInteractionMode = (e, boo) => {
        const id = e.id;
                //update root element
                setElement(prevElements =>
                    prevElements.map((element) => {
                        return{
                            ...element,
                            interaction_mode: element.id == id ? boo : element.interaction_mode
                        }
                    })
                )
                //update active element so change appears immediately
                const ElementToBeUpdated = element.filter((x) => x.id == id);
                const updatedElement = ElementToBeUpdated.map((element) => {
                    return{
                        ...element,
                        interaction_mode: element.id == id ? boo : element.interaction_mode
                    }
                })
                setActiveElement(updatedElement[0]);    
    }

    const deleteContainerChildrenDb = async(props) => {
        if (!props.grouped_by) return;
        
        // Get current container from local state
        const container = element.find(el => el.id === props.grouped_by);
        const updatedChildren = container.children.filter(child => child.id !== props.id);
    
        const { error } = await supabase
            .from('Container')
            .update({ children: updatedChildren })
            .eq('id', props.grouped_by);
    
        if (error) {
            console.error('Error updating container:', error);
        }
    };

    const deleteElementLocally = (props) => {
        setElement(prevElements => {
            // First create new array with child removed from container if needed
            const updatedElements = prevElements.map(el => 
                el.id === props.grouped_by 
                    ? {...el, children: el.children.filter(child => child.id !== props.id)} 
                    : el
            );

            if(props.grouped){
                deleteContainerChildrenDb(props);
            }
            
            // Then find and remove the element itself
            const elementIndex = updatedElements.findIndex(el => el.id === props.id);
            if (elementIndex === -1) return updatedElements;
            
            return [
                ...updatedElements.slice(0, elementIndex),
                ...updatedElements.slice(elementIndex + 1)
            ];
        });
    };
    

    const deleteElement = async(props) => {
        //console.log(props)
        switch (props.type) {
            case 'textfield':
                deleteTextField(props);
                deleteElementLocally(props);
                break;
            case 'button':
                await deleteButton(props);
                deleteElementLocally(props);
                break;
            case 'text':
                deleteText(props);
                deleteElementLocally(props);
                break;
            case 'image':
                deleteImage(props);
                deleteElementLocally(props);
                break;
            case 'switch':
                deleteSwitch(props);
                deleteElementLocally(props);
                break;
            case 'container':
                deleteContainer(props); 
                deleteElementLocally(props); 
            default:
                break;
        }
       
    }

    

    const value = useMemo(()=> ({
        screens,
        addScreen,
        changeScreenActiveState,
        deactivateAllScreens,
        setCanvasIsSelected,
        isCanvasSelected,
        element,
        setElement,
        addElement,
        setCurrentActiveElement,
        activeElement,
        setScreenVisible,
        updateElement,
        aScreenIsActive,
        latestContextMenu,
        updateLatestContextMenu,
        setElementPrototyping,
        setElementInteractionMode,
        setCurrentFileFn,
        currentFile,
        loadElements,
        loadScreens,
        updateElementProto,
        updateElementCustomPanel,
        updateElementStyling,
        loaded,
        sortElements,
        updateElementZindex,
        deleteElement,
        setCurrentFileDetails,
        currentFileName,
        currentFileScreenSize,
        resetAppStates,
        screensLoaded,
        newScreenFlag,
        setNewScreenFlag,
        makeElementContainerChild,
        removeElementContainerChild,
        updateChildZindexDb,
        updateScreenDb,
        setScreenAsHome,
        updateScreenPanel
    }))

    return ( 
        <EditorContext.Provider value={value}>
            {props.children}
        </EditorContext.Provider>
    )
}

export const useEditorContext = () => React.useContext(EditorContext);