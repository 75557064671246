import { getAvailableFonts } from "@remotion/google-fonts";
import React, { useCallback, useEffect, useState } from "react";
import { fontSelection } from "../../../../../../fonts/fonts"; 
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import './fontweight.css';
import '../../elementpanels/elementpanel.css'

const FontWeight = ({item, setFontWeight, font, onChange}) => {

  const newFonts = getAvailableFonts();

  const [fontArr, setFontArr] = useState(fontSelection);
  const [fontWeightSelect, setFontWeightSelect] = useState(item.font_weight);
  const [dropDownSelection, setDropDownSelection] = useState([]);

  useEffect(()=>{

    const loadFontWeights = async()=>{
        const fontToLoad = newFonts.filter((i) => i.fontFamily == font);
        const loaded = await fontToLoad[0].load();
        const info = loaded.getInfo();
      const styles = Object.keys(info.fonts);
      for (const style of styles) {
        const weightObject = info.fonts;
        const weights = Object.keys(weightObject);
        for (const weight of weights) {
          const scripts = Object.keys(weightObject[weight]);
          setDropDownSelection(scripts);
        }
      }
    }
    if(font){
      loadFontWeights()
    }
  },[font])

 
  return (
    <div>
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <div className="element-panel-dropdown-trigger">
                  <p>Font weight</p>
                  <button className="Trigger" aria-label="Customise options" style={{fontFamily: fontWeightSelect}}>
                    {fontWeightSelect}
                    <ChevronDownIcon color="#777FBD" width={16} height={16}/>
                  </button>
                </div>
                
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="element-panel-dropdown-content">

                    {dropDownSelection.map((f) => {
                        return (
                            <DropdownMenu.Item className="DropdownMenuItem" key={f} value={f} onClick={()=>{setFontWeightSelect(f); setFontWeight(f); onChange(f); }}>
                            {f}
                            </DropdownMenu.Item>
                        );
                    })}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    </div>
  );
};

export default FontWeight;