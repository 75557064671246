import React, {useRef, useEffect, useLayoutEffect, useState} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer, Line } from 'react-konva';
import { useMeasure } from "react-use";
import { Portal } from 'react-konva-utils';
import { useEditorContext } from "../../../../../context/editor/editorcontext";
import { calcWidthHeight } from "../../../../../utils/editor/calcWidthHeight";
import { Item } from "@radix-ui/react-dropdown-menu";

function CoreTextField({stageRef, keyVal, shapeProps, setDomensions, isSelected, setSelectedId, elWidth, elHeight, onSelect, onDragMove, onDragEnd, onTransform, onTransformEnd}) {

    const shapeRef = useRef();
    const trRef = useRef();

    const editorContext = useEditorContext();

    const [domDimensions, setDomDimensions] = useState({width:50, height:50})
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [loaded, setLoaded] = useState(false);
    const [loadedWidth, setLoadedWidth] = useState(null);
    const [loadedHeight, setLoadedHeight] = useState(null);

    const [interactionMode, setInteractionMode] = useState(false);
    const [value, setValue] = useState(shapeProps.value);
    const [interactionVal, setInteractionVal] = useState(shapeProps.value);

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
        setDomensions({
            width: width,
            height: height
        })
    },[width, height])
  
    useEffect(() => {
      if (isSelected) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
    
    useEffect(()=>{
        if(loaded == false){
            setLoadedWidth(shapeProps.width);
            setLoadedHeight(shapeProps.height);
            setLoaded(true);
        }
    },[])

    useEffect(()=>{
        if(editorContext.activeElement?.id == shapeProps.id){
            //console.log('triggering element', shapeProps.show_value )
            setSelectedId(shapeProps.id);
            setInteractionMode(editorContext.activeElement.interaction_mode);
            setInteractionVal(shapeProps.value);
        }
    },[editorContext.activeElement]);

    // useEffect(()=>{
    //     if(editorContext.activeElement?.id == shapeProps.id){
    //         console.log('triggering element', shapeProps.show_value);
    //         setValue(shapeProps.show_value ? shapeProps.value : null);
            
    //     }
    // },[shapeProps])

    const getVal = () => {
        if(!shapeProps.value.trim() && !interactionMode){
            // value is empty
            //console.log('VALUE IS EMPTY');
            return '';
        }else if(interactionMode){
            // interaction mode is on
            //console.log('Interaction mode is on');
            return interactionVal;
        }else{
            return shapeProps.value;
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(isSelected){
                // console.log('keys', key)
                if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
                    const props = {
                        id: shapeProps.id,
                        type: shapeProps.type,
                        grouped: shapeProps.grouped,
                        grouped_by: shapeProps.grouped_by,
                    }
                    editorContext.deleteElement(props);
                  }              
            }
        }
        //add a global event for `keydown`
        window.addEventListener('keydown', handleKeyDown)
       //clean up after component unmounted
       return () => window.removeEventListener('keydown', handleKeyDown)
     }, [isSelected])

     React.useEffect(() => {
        if(!isSelected) return
        const konvaContent = stageRef.content;
        const transformerElement = trRef.current.getNode().getCanvas()._canvas;
        
        transformerElement.style.position = 'absolute';
        transformerElement.style.top = '0';
        transformerElement.style.left = '0';
        transformerElement.style.zIndex = '9999';
        transformerElement.style.pointerEvents = 'none';
        
        konvaContent.appendChild(transformerElement);
    }, [isSelected]);
 

    return(
        <>
            <Group 
                key={keyVal}
                draggable={true} 
                onDragMove={onDragMove} 
                onDragEnd={onDragEnd} 
                x={shapeProps.xpos}
                y={shapeProps.ypos}
                width={parseFloat(elWidth)}
                height={parseFloat(elHeight)}
            >
                <Html divProps={{ style: { pointerEvents: interactionMode && isSelected ? "auto" : "none",}}}>
                    <div ref={dimensionRef} style={{
                        width: parseFloat(elWidth), 
                        height: parseFloat(elHeight), 
                        padding: 0,
                        margin: 0,
                    }}>
                        <input 
                            placeholder={shapeProps.placeholder} 
                            value={getVal()} 
                            onChange={(e)=>{setInteractionVal(e.target.value)}} 
                            style={{
                                width: '100%', 
                                height: '100%', 
                                fontFamily: shapeProps.font, 
                                fontSize:  parseInt(shapeProps.font_size) * shapeProps.scaleX,
                                color: shapeProps.font_colour,
                                borderTopLeftRadius: shapeProps.border_radius_tl,
                                borderTopRightRadius: shapeProps.border_radius_tr,
                                borderBottomLeftRadius: shapeProps.border_radius_bl,
                                borderBottomRightRadius: shapeProps.border_radius_br,
                                borderWidth: shapeProps.border_width,
                                borderColor: shapeProps.border_colour,
                                borderStyle: 'solid',
                                backgroundColor: shapeProps.fill,
                                boxSizing: 'border-box',
                                paddingLeft: 5,
                                paddingRight: 5,
                            }}
                        /> 
                    </div>
                </Html>
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    width={domDimensions.width}
                    height={domDimensions.height}
                    fill={'transparent'} 
                    ref={shapeRef}  
                    name="shape"
                />
            </Group> 

            { isSelected && (
                <>
                    <Transformer
                        ref={trRef}
                        flipEnabled={false}
                        anchorCornerRadius={100}
                        enabledAnchors={["bottom-right", "bottom-center", "middle-right"]}
                        padding={8}
                        onTransformEnd={onTransformEnd}
                        onTransform={onTransform}
                        rotationSnaps={[0, 90, 180, 270]}
                        rotationSnapTolerance={10}
                        keepRatio={true}
                    />
                </>
            )}

       </>
    )
}

export default CoreTextField;