import React, { useState, useEffect, useRef, Fragment } from 'react';
import { supabase } from '../../../supabase/supabaseclient';
import { FunctionsHttpError } from '@supabase/supabase-js'
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../context/auth/authcontext';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import './checkout.css';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`, {
});

function Checkout(){

    // const options = {fetchClientSecret};

    const navigate = useNavigate();
    const authContext = useAuthContext();

    const {num_users, teamId} = useParams();
    const [options, setOptions] = useState({});
    const [loaded, setLoaded] = useState(false);


    const getCheckout = async() => {
        console.log('calling stripe')
        console.log('checkout quantity', num_users);
        console.log('this is the team', teamId);
        
        const { data, error } = await supabase.functions.invoke('stripe-checkout',{
            body:{
                domain: window.location.origin,
                num_of_users: num_users,
                team_id: teamId
            }
        });

        if(data){
         console.log('this is stripe data', data);
         setOptions({clientSecret: data.client_secret})
         setLoaded(true);
         
        }
 
        if(error) {
            console.log('Function returned an error', error)
        }  
    }

    useEffect(()=>{
        getCheckout()
    },[]);

    return (

        <div className='signup-main'>
            <div className='signup-header-container'>
                <h1 className="signup-header">Rivveo <span>BETA</span></h1>

            </div>

            {
                loaded ? 

                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <div className='stripe-main'>
                        <EmbeddedCheckout/>
                    </div>
                    
                </EmbeddedCheckoutProvider>

            :
                null
            }            
        </div>

    )

}

export default Checkout;