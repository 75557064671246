import React, { useEffect, useState } from "react"
import * as Popover from '@radix-ui/react-popover';
import './switchpanel.css'
import { useEditorContext } from "../../../../../../context/editor/editorcontext";
import { HexColorPicker, HexColorInput } from "react-colorful";
import '../../elementpanels/elementpanel.css';

function SwitchPanel ({item}){

    const editorContext = useEditorContext();
    const [disabledColour, setDisabledColour] = useState(item.disabled_colour);
    const [activeColour, setActiveColour] = useState(item.active_colour);
    const [notchColour, setNotchColour] = useState(item.notch_colour);

    useEffect(()=>{
       setDisabledColour(item.disabled_colour);
       setActiveColour(item.active_colour);
       setNotchColour(item.notch_colour);
    },[item])
    
    const updateElement = (activecolour, disabledcolour, notchcolour) => { 
        if(item?.id == editorContext.activeElement?.id){
            const props = { 
                type: item.type,
                id: item.id,
                grouped: item.grouped,
                grouped_by: item.grouped_by,
                active_colour: activecolour,
                disabled_colour: disabledcolour,
                notch_colour: notchcolour 
            }
            editorContext.updateElementCustomPanel(props);
        }
    }

    const handlePanel = (activecolour, disabledcolour, notchcolour) => {
        setActiveColour(activecolour);
        setDisabledColour(disabledcolour);
        setNotchColour(notchcolour);
        updateElement(activecolour, disabledcolour, notchcolour);
    }

    return(
        <div className="element-panel-container">
            <div className="element-panel-title">
                <p>Switch</p>
            </div>
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Active colour</p>
                            <div>
                                <div style={{width: 24, height: 24, background: activeColour}}/> 
                                <p>{activeColour}</p>
                            </div>
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content className="PopoverContent" sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={activeColour} onChange={(e)=> handlePanel(e, disabledColour, notchColour)} />
                            <HexColorInput color={activeColour} onChange={(e)=> handlePanel(e, disabledColour, notchColour)} />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Disabled colour</p>
                            <div>
                                <div style={{width: 24, height: 24, background: disabledColour}}/>
                                <p>{disabledColour}</p>
                            </div>
                            
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content className="PopoverContent" sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={disabledColour} onChange={(e)=> handlePanel(activeColour, e, notchColour)}  />
                            <HexColorInput color={disabledColour} onChange={(e)=> handlePanel(activeColour, e, notchColour)}  />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Notch colour</p>
                            <div>
                                <div style={{width: 24, height: 24, background: notchColour}}/>
                                <p>{notchColour}</p>
                            </div>
                            
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content className="PopoverContent" sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={notchColour} onChange={(e)=> handlePanel(activeColour, disabledColour, e)} />
                            <HexColorInput color={notchColour} onChange={(e)=> handlePanel(activeColour, disabledColour, e)} />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    )
}

export default SwitchPanel