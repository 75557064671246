import React, {useEffect, useState} from 'react';
import './contextmenu.css';
import { IconButton } from '@radix-ui/themes';
import { PlayIcon, TargetIcon, Crosshair1Icon } from '@radix-ui/react-icons'
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useEditorContext } from '../../../context/editor/editorcontext';
import { useMeasure } from "react-use";


function ContextMenu ({item, isSelected, domensions, xpos, ypos, width, height}){

    const editorContext = useEditorContext()
    const [element, setElement] = useState();
    const [visible, setVisible] = useState(false);
    const [prototyping, setPrototyping] = useState(false);
    const [interactionMode, setInteractionMode] = useState(false);
    const [dimensionRef, measurements] = useMeasure();



    const updatePrototyping = () => {
        if(editorContext.activeElement.prototyping == true){
            setPrototyping(true);
        }else{
            setPrototyping(false);
        }
    }

    const updateInteractionMode = () => {
        if(editorContext.activeElement.interaction_mode && editorContext.activeElement.interaction_mode == true){
            setInteractionMode(true);
        }else{
            setInteractionMode(false);
        }
    }

    useEffect(()=>{
      if(isSelected){
        updatePrototyping();
        updateInteractionMode();
      }
    },[item])

    const handlePrototype = () => {
         //console.log(editorContext.activeElement);
        if(editorContext.activeElement.prototyping){
            // set element prototyping to disabled
            setPrototyping(false);
            editorContext.setElementPrototyping(editorContext.activeElement, false);
        }else{
            // set element prototyping to enabled
            setPrototyping(true);
            editorContext.setElementPrototyping(editorContext.activeElement, true);
        }
    }

    const handleInteractionMode = () => {
        //console.log(editorContext.activeElement);
       if(editorContext.activeElement.interaction_mode){
           // set element prototyping to disabled
           setInteractionMode(false);
           editorContext.setElementInteractionMode(editorContext.activeElement, false);
       }else{
           // set element prototyping to enabled
           setInteractionMode(true);
           editorContext.setElementInteractionMode(editorContext.activeElement, true);
       }
   
    }
    
    return(
        <>
            {
               isSelected && (
                    <Group x={parseFloat(xpos) + (width - measurements.width) /2} y={parseFloat(ypos) - 80}>
                        <Html>
                            <div ref={dimensionRef} className='contextmenucontainer'> 
                                <div className='contextmenudimscontainer'>
                                    <div className='contextmenudims'>
                                        <p>x:</p>
                                        <p>{xpos?.toFixed(3)}</p>
                                    </div>
                                    <div className='contextmenudims'>
                                        <p>y:</p>
                                        <p>{ypos?.toFixed(3)}</p>
                                    </div>
                                    <div className='contextmenudims'>
                                        <p>w:</p>
                                        <p>{width?.toFixed(3)}</p>
                                    </div>
                                    <div className='contextmenudims'>
                                        <p>h:</p>
                                        <p>{height?.toFixed(3)}</p>
                                    </div>
                                </div>
                                

                                {
                                    Object.hasOwn(editorContext.activeElement, 'interaction_mode') && editorContext.activeElement.interaction_mode !== undefined &&
                                    <IconButton className={interactionMode ? 'contextmenubuttonactive' : 'contextmenubutton'} onClick={() => handleInteractionMode()}>
                                        <PlayIcon width={30} height={30} fill='#fff'/>
                                    </IconButton>
                                }

                                <IconButton className={prototyping ? 'contextmenubuttonactive contextmenubuttonprototypingactive' : 'contextmenubutton'} onClick={() => handlePrototype()}>
                                    <Crosshair1Icon width={30} height={30} fill='#fff'/>
                                </IconButton>
                                
                            </div>
                        </Html>
                    </Group> 
                )
            }
        </>
        
    )
}

export default ContextMenu;