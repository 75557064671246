// CodePanel.js
import React, { useState, useEffect } from 'react';
import { useEditorContext } from '../../../context/editor/editorcontext';
import { Copy, Check } from 'lucide-react';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx';
import 'prismjs/themes/prism-tomorrow.css';
import { generateComponentCode } from '../utils/elementParser';

const CodePanel = () => {
  const editorContext = useEditorContext();
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('react');
  const [rawCode, setRawCode] = useState('');
  const [highlightedCode, setHighlightedCode] = useState('');

  useEffect(() => {
    if (editorContext.activeElement) {
      try {
        const code = generateComponentCode(editorContext.activeElement);
        setRawCode(code);
        const highlighted = Prism.highlight(code, Prism.languages.jsx, 'jsx');
        
        setHighlightedCode(highlighted);
        
        // Force Prism to re-highlight
        requestAnimationFrame(() => {
          Prism.highlightAll();
        });
      } catch (error) {
        console.error('Error generating code:', error);
        setRawCode('// Error generating code');
        setHighlightedCode('// Error generating code');
      }
    }
  }, [editorContext.activeElement, activeTab]);

  const handleCopy = async () => {
    if (!rawCode) return;
    try {
      await navigator.clipboard.writeText(rawCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy code:', err);
    }
  };

  if (!editorContext.activeElement) {
    return (
      <div className="code-editor-container">
        <div className="text-gray-400">Select an element to view its code</div>
      </div>
    );
  }

  return (
    <div className="code-editor-container">
      <div className="code-panel-header">
        <div className="code-tabs">
          <button
            className={`tab-button ${activeTab === 'react' ? 'active' : ''}`}
            onClick={() => setActiveTab('react')}
          >
            React
          </button>
        </div>
        <button onClick={handleCopy} className="copy-button">
          {copied ? <Check size={14} /> : <Copy size={14} />}
          {copied ? 'Copied!' : 'Copy'}
        </button>
      </div>
      
      <div className="code-preview">
        <pre className="language-jsx">
          <code 
            dangerouslySetInnerHTML={{ __html: highlightedCode }}
          />
        </pre>
      </div>
    </div>
  );
};

export default CodePanel;