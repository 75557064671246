import React, {useRef, useEffect, useLayoutEffect, useState, memo} from "react";
import { Html } from 'react-konva-utils';
import { supabase } from "../../../../../supabase/supabaseclient";
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { usePrototypeContext } from "../../../../../context/prototype/prototypecontext";
import { prototypeSwitcher } from "../../../utils/prototypeGenerator";
import { useMeasure } from "react-use";
import { RepositionElements, adjustElementPosition } from "../../../utils/repositionElements";
import { Spring, animated, useSpring, useSpringValue} from '@react-spring/konva';
import Konva from "konva";
import executeGlobalAnim from "../../../utils/executeGlobalAnim";

function CorePlayerImage({key, element, screenWidth, screenHeight, canvasX, canvasY}) {

    const prototypeContext = usePrototypeContext();
    const [domDimensions, setDomDimensions] = useState({width:50, height:50});
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [dataUrl, setDataUrl] = useState();
    const [imgLoaded, setImgLoaded] = useState(false);

    const [activateElement, setActivateElement] = useState(false);
    const [value, setValue] = useState(element.value)
    const inputRef = React.useRef();

    const [translatedX, setTranslatedX] = useState(0);
    const [translatedY, setTranslatedY] = useState(0);

    const [elementX, setElementX] = useState(0);
    const [elementY, setElementY] = useState(0);
    
    const animRef = useRef();

    const runAnim = (xval, animTrigger, anim) => {
        const diff = Math.abs(translatedX - element.xpos);
        const finalExitPos = translatedX + element.xpos + screenWidth + diff;
        
        new Konva.Tween({
            node: animRef.current,
            easing: Konva.Easings.Linear,
            duration: 0.2,
            x: animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight' 
                ? -element.width
                : xval,
            onFinish: () => {
                if(animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight'){
                    setElementX(finalExitPos);
                } else {
                    setElementX(xval);
                }
            }
        }).play();
    }

    useEffect(()=>{
            //console.log(element);
            const elementScreen = prototypeContext.screens.find((i) => i.id == element.screen);
            const screen1 = {xpos: elementScreen?.xpos, ypos: elementScreen?.ypos} 
            const screen2 = {xpos: canvasX - screenWidth/2, ypos: canvasY - screenHeight/2}
            //console.log(screen1, screen2)
            const xy = RepositionElements(screen1, screen2);
            const newPos = adjustElementPosition(element, prototypeContext.currentRenderedScreen, xy, screenWidth)

            setTranslatedX(xy.xpos);
            setTranslatedY(xy.ypos);
            setElementX(newPos);
            setElementY(xy.ypos + element.ypos);
            //console.log(xy, xy.ypos, element.ypos);
    },[])

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
    },[width, height])

    useEffect(()=>{
        //wait for animation trigger
        // anim trigger
        if( prototypeContext.animTrigger.type == 'global' && prototypeContext.animTrigger.exitScreen == element.screen && prototypeContext.animTrigger.sequence === 'exit')
        {
            //animate all elements that are in the current screen
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.exitScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.exitScreenAnimation);
            setActivateElement(false);

        }else if (prototypeContext.animTrigger.type == 'global' && prototypeContext.animTrigger.entranceScreen == element.screen && prototypeContext.animTrigger.sequence === 'entrance')
        {
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.entranceScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.entranceScreenAnimation);

            setActivateElement(false);
            // stop state being called multiple times
            if(prototypeContext.currentRenderedScreen.id != element.screen){
                const screen = prototypeContext.screens.filter((i)=>i.id == element.screen);
                prototypeContext.setCurrentScreen(screen[0]);
                prototypeContext.setCurrentRenderedElements(element);
            }
            
        }else if(prototypeContext.animTrigger.type == 'individual' && prototypeContext.animTrigger.originElementId == element.id){
            // animate only this element
            console.log('button individual', element.id);
        }
    },[prototypeContext.animTrigger])


    const handlePrototype = (event, element) => {
        console.log('handling prototype in image')
        //setActivateElement(true)
        const res = prototypeContext.triggerPrototype(event, element);
        //console.log('calling handlePrototype', event, res);
        if(res == false && event == 'On click'){
            // for 'on enter' to work On click needs
            // to make text field editable
            setActivateElement(true);
            //inputRef.current.focus();
        }else if(res == true){
            setActivateElement(true);
        }else{
            setActivateElement(false);
            //inputRef.current.blur();
        }
    }

    useEffect(()=>{
        const loadImage = async() => {
            const { data, error } = await supabase.storage
            .from('Rivveo_File_Images')
            .createSignedUrl(element.url, 86400);
    
            if(data){
                console.log(data.signedUrl);
                setDataUrl(data.signedUrl);
                setImgLoaded(true)
            }

            if(error){
                console.log(error)
            }
        }
        loadImage();
    },[element.url, element.image_name]);

    return(
        <>
            {
                <Group 
                    key={key}
                    ref={animRef}
                    x={element.grouped ? element.xpos : elementX}
                    y={element.grouped ? element.ypos : elementY}
                    width={element.width}
                    height={element.height}
                >
                    <Html divProps={{ style:{pointerEvents: activateElement ? 'auto' : 'none'}}}>
                        <div ref={dimensionRef}>
                            {
                                imgLoaded &&
                                <img 
                                style={{
                                    width: element.width, 
                                    height: element.height, 
                                    borderTopLeftRadius: element.border_radius_tl,
                                    borderTopRightRadius: element.border_radius_tr,
                                    borderBottomLeftRadius: element.border_radius_bl,
                                    borderBottomRightRadius: element.border_radius_br,
                                    objectFit: element.aspect_ratio
                                }}
                                src={dataUrl}
                                />
                            }
                           
                        </div>
                    </Html>
                    <Rect
                        onClick={()=>{handlePrototype('On click', element)}}
                        width={domDimensions.width}
                        height={domDimensions.height}
                        fill={'transparent'}
                    />
                </Group>  

            }
        </>
    )
}

export default memo(CorePlayerImage);
