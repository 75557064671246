
// Check rect r2 is completely inside bounds of r1
// Each rect should be of form {left: , top: , width: , height}
export function rectInRect(r1, r2) {
  // First correct r2's right/bottom coordinates
  const elementRect = {
      left: r2.left,
      top: r2.top,
      right: r2.left + r2.width,
      bottom: r2.top + r2.height
  };

  // Check if there's any overlap at all
  return !(elementRect.left > r1.right || 
           elementRect.right < r1.left || 
           elementRect.top > r1.bottom ||
           elementRect.bottom < r1.top);
}

// Check point is inside rect
export function ptInRect(pt, rect){
    if ( (pt.x >= rect.left) && (pt.x <= rect.right) && (pt.y >= rect.top) && (pt.y <= rect.bottom)){ 
    return true;
  } 
  return false;
}